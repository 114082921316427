import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ProgramComponent } from "./program/program.component"
import { HttpClientModule } from "@angular/common/http"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AppRoutingModule } from "../../../../app-routing.module"
import { MaterialModule } from "../../../../material.module"

import { ProgramParametersComponent } from "./program-parameters/program-parameters.component"
import { JobResults } from "./job-results/job-results.component"
import { CustomMatSelectSearchModule } from "src/app/custom-mat-search/custom-mat-select-search.module"
import { JobResultsRedesignComponent } from "./job-results-redesign/job-results-redesign.component"
import { CustomTooltipComponent } from "../../components/custom-tooltip/custom-tooltip.component"
import { CustomTooltipDirective } from "../../components/custom-tooltip/appCustomTooltip"
import { TooltipDirective } from "./custom-directives/tooltip.directive"

import { PortalModule } from "@angular/cdk/portal"
import { TruncatePipe } from "../../pipes/truncate.pipe"

@NgModule({
  declarations: [
    ProgramComponent,
    JobResults,
    ProgramParametersComponent,
    JobResultsRedesignComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    TooltipDirective,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PortalModule,
    CustomMatSelectSearchModule,
  ],
  exports: [ProgramComponent, JobResults],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProgramsModule {}
