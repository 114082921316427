<app-current-organization></app-current-organization>
<section class="bg-sample pt30px" *ngFor="let org of infoOrg">
  <div *ngIf="!isLoading" class="content container-row-only space-between">
    <div class="left-container-settings">
      <div class="card-basic mb20px">
        <div class="container-row-only space-between">
          <div class="name-org-info">Organization Info</div>
          <div>
            <!-- <button mat-flat-button>Edit</button> -->
          </div>
        </div>
        <div class="container-row-only space-between mb30px">
          <div class="column">
            <div class="title-info-org">Organization Name</div>
            <div class="get-info">
              {{ org.name }}
            </div>
          </div>
          <div class="column">
            <div class="title-info-org">Organization Id</div>
            <div class="get-info">
              {{ org.id }}
            </div>
          </div>
          <div class="column">
            <div class="title-info-org">Logo</div>
            <div class="get-info">
              <img
                src="{{ org.logo }}"
                alt=" {{ org.name }}"
                class="logo-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-container-settings"></div>
  </div>
</section>
<div *ngIf="isLoading">
  <div class="container-spinner">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
