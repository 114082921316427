<section class="container">
  <div class="container-row closing-btn">
    <button mat-icon-button (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="create-set">
    <h2 class="title">Confirm Deletion</h2>
    <h4 class="sub-title">Are you sure you want to delete this sample set?</h4>
    <div class="button-wrapper gap15 mt10px">
      <button mat-flat-button (click)="onCancel()">Cancel</button>
      <button mat-flat-button color="warn" (click)="onConfirm()">Delete</button>
    </div>
  </div>
</section>
