import { HttpClient } from "@angular/common/http"
import { Component, Inject } from "@angular/core"
import { FormGroup, FormBuilder, Validators } from "@angular/forms"
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog"
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from "@angular/material/snack-bar"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"
import { RecoverUserDialogComponent } from "../recover-user-dialog/recover-user-dialog.component"

@Component({
  selector: "app-edit-recover-user-dialog",
  templateUrl: "./edit-recover-user-dialog.component.html",
  styleUrls: ["./edit-recover-user-dialog.component.scss"],
})
export class EditRecoverUserDialogComponent {
  editUserForm!: FormGroup
  data: any
  name: any
  role: any
  email: any
  filterUser: any[] = []
  family_name: any
  currentUser: any
  id: any

  roles = [
    { value: "Org Admin", key: "1" },
    { value: "Super Admin", key: "2" },
    { value: "Regular User", key: "0" },
  ]
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  roleCognito: any

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    public http: HttpClient,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public dataDaiolog: any,
  ) {
    this.roleCognito = this.authService.role
    this.id = dataDaiolog.id
    this.roleCognito = this.authService.role
    if (this.roleCognito == "2") {
      this.roles = [
        { value: "Org Admin", key: "1" },
        { value: "Regular User", key: "0" },
      ]
    }

    if (dataDaiolog.role == 0) {
      this.role = "Regular User"
    } else if (dataDaiolog.role == 1) {
      this.role = "Org Admin"
    } else if (dataDaiolog.role == 2) {
      this.role = "Super Admin"
    }
  }

  ngOnInit() {
    console.log(this.dataDaiolog)

    this.editUserForm = this.formBuilder.group({
      firstName: [this.dataDaiolog.givenName || ""],
      middleName: [this.dataDaiolog.middleName || ""],
      lastName: [this.dataDaiolog.familyName || ""],
      bucket: [this.dataDaiolog.bucket || ""],
      id: [this.dataDaiolog.id],
      role: [this.dataDaiolog.role || ""],
      enabled: [this.dataDaiolog.enabled || ""],
    })
    if (this.authService.permissions?.includes("update:platform_users")) {
      this.editUserForm.get("role")?.disable()
    }
  }
  editUser() {
    let user_info = this.editUserForm.value
    user_info.role = this.roles
      .filter((x) => user_info.role == x.value)
      .map((x) => x.key)[0]
    console.log("Updating user:", user_info)
    if (this.roleCognito == "1") {
      user_info.role = "0"
    } else if (user_info.role == undefined) {
      user_info.role = this.roles
        .filter((x) => this.role == x.value)
        .map((x) => x.key)[0]
    }
    this.http.post("update_user/", user_info).subscribe((res: any) => {
      if (res) {
        console.log("+++++++", res)
        this.commonService.announceUserUpdate()
      }
      this.snackbar.openFromComponent(CustomSnackbarComponent, {
        data: { name: "User updated successfully" },
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["my-custom-snackbar-error", "mat-toolbar", "mat-primary"],
        duration: 10000,
      })
    })

    this.dialog.closeAll()
  }

  openReactivateAccountDialog(id: string, email: string, enabled: true) {
    const dialogRef = this.dialog.open(RecoverUserDialogComponent, {
      width: "380px",
      // height: '490px',
      hasBackdrop: true,
      data: { id: this.id, enabled: enabled, email: email },
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      console.log(res)
    })
  }
}
