<section class="bg-sample pt30px">
  <div class="content container-row">
    <div class="left-container text-grey">
      <article>
        <div class="title-set mb30px">Terms and Conditions</div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. In eu mi
          bibendum neque egestas congue quisque. Amet mauris commodo quis
          imperdiet massa tincidunt nunc. Odio eu feugiat pretium nibh ipsum. Et
          odio pellentesque diam volutpat commodo sed egestas. Nisl purus in
          mollis nunc. Vitae nunc sed velit dignissim sodales ut eu sem integer.
          Lacus laoreet non curabitur gravida arcu ac tortor dignissim.
          Ullamcorper a lacus vestibulum sed arcu non. Pretium aenean pharetra
          magna ac placerat vestibulum. Tincidunt eget nullam non nisi est. Eu
          feugiat pretium nibh ipsum consequat. Ac tortor vitae purus faucibus
          ornare suspendisse.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. In eu mi
          bibendum neque egestas congue quisque. Amet mauris commodo quis
          imperdiet massa tincidunt nunc. Odio eu feugiat pretium nibh ipsum. Et
          odio pellentesque diam volutpat commodo sed egestas. Nisl purus in
          mollis nunc. Vitae nunc sed velit dignissim sodales ut eu sem integer.
          Lacus laoreet non curabitur gravida arcu ac tortor dignissim.
          Ullamcorper a lacus vestibulum sed arcu non. Pretium aenean pharetra
          magna ac placerat vestibulum. Tincidunt eget nullam non nisi est. Eu
          feugiat pretium nibh ipsum consequat. Ac tortor vitae purus faucibus
          ornare suspendisse.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. In eu mi
          bibendum neque egestas congue quisque. Amet mauris commodo quis
          imperdiet massa tincidunt nunc. Odio eu feugiat pretium nibh ipsum. Et
          odio pellentesque diam volutpat commodo sed egestas. Nisl purus in
          mollis nunc. Vitae nunc sed velit dignissim sodales ut eu sem integer.
          Lacus laoreet non curabitur gravida arcu ac tortor dignissim.
          Ullamcorper a lacus vestibulum sed arcu non. Pretium aenean pharetra
          magna ac placerat vestibulum. Tincidunt eget nullam non nisi est. Eu
          feugiat pretium nibh ipsum consequat. Ac tortor vitae purus faucibus
          ornare suspendisse.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. In eu mi
          bibendum neque egestas congue quisque. Amet mauris commodo quis
          imperdiet massa tincidunt nunc. Odio eu feugiat pretium nibh ipsum. Et
          odio pellentesque diam volutpat commodo sed egestas. Nisl purus in
          mollis nunc. Vitae nunc sed velit dignissim sodales ut eu sem integer.
          Lacus laoreet non curabitur gravida arcu ac tortor dignissim.
          Ullamcorper a lacus vestibulum sed arcu non. Pretium aenean pharetra
          magna ac placerat vestibulum. Tincidunt eget nullam non nisi est. Eu
          feugiat pretium nibh ipsum consequat. Ac tortor vitae purus faucibus
          ornare suspendisse.
        </p>
      </article>
    </div>
  </div>
</section>
