import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HomeComponent } from "./home/home.component"
import { SidenavComponent } from "./sidenav/sidenav.component"
import { MaterialModule } from "../../../../../app/material.module"
import { FormsModule } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { AppRoutingModule } from "../../../../../app/app-routing.module"

@NgModule({
  declarations: [HomeComponent, SidenavComponent],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
  ],
  exports: [HomeComponent, SidenavComponent],
  providers: [],
})
export class HomeModule {}
