<div class="container-row-only">
  <div class="column w80 job-btn">
    <div class="bold mt6px">A file with this name is still being uploaded!</div>
  </div>
  <div class="btn-close">
    <button mat-button (click)="snackBarRef.dismiss()">
      <span class="text-orange">Close</span>
    </button>
  </div>
</div>
