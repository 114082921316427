import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { CommonService } from "src/app/core/services/commonservice.service"
import { UserLoginService } from "src/app/core/services/user-login.service"
import { CognitoCallback } from "../../../../../core/services/cognito-forgot.service"
import { AuthService } from "src/app/core/services/auth.service"
import { CognitoUserPool } from "amazon-cognito-identity-js"
import { environment } from "src/environments/environment"

@Component({
  selector: "app-f-pass",
  templateUrl: "./f-pass.component.html",
  styleUrls: ["./f-pass.component.scss"],
})
export class FPassComponent implements CognitoCallback {
  email: string = ""
  errorMessage: string
  isLogged: boolean = false

  constructor(
    public router: Router,
    public userService: UserLoginService,
    private commonService: CommonService,
    public authService: AuthService,
  ) {
    const poolData = {
      UserPoolId: environment.cognito.userPoolId, // Your user pool id here
      ClientId: environment.cognito.userPoolWebClientId, // Your client id here
    }

    const userPool = new CognitoUserPool(poolData)
    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser != null) {
      this.isLogged = true
    }

    //@ts-ignore
    this.errorMessage = null
  }

  onNext() {
    //@ts-ignore
    this.errorMessage = null
    this.userService.forgotPassword(this.email, this)
    this.commonService.savedEmail.next(this.email)
    localStorage.setItem("email", this.email)
  }

  cognitoCallback(message: string, result: any) {
    if (message == null && result == null) {
      //error
      this.router.navigate(["/forgot-pass/", this.email])
    } else {
      //success
      this.errorMessage = message
    }
  }
}
