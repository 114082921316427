import { LiveAnnouncer } from "@angular/cdk/a11y"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable, signal } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Sort } from "@angular/material/sort"
import { BehaviorSubject, Subject, of } from "rxjs"
import { environment } from "../../../environments/environment"

import CryptoJS from "crypto-js"

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    private http: HttpClient,
    private _liveAnnouncer: LiveAnnouncer,
  ) {}

  sampleSetId = new BehaviorSubject<any>("")
  query: any

  trigger = new Subject()
  savedEmail = new Subject()
  page = 0
  pageSize = 50
  csvRecords = new BehaviorSubject(<any>[])
  lastPage = false
  pending = false
  searchControl = new FormControl()
  sortControl = new FormControl()
  orderType = "set_name"
  totalSizePage = <number>0
  inputValue = <string>""
  selectedItems = <any>[]
  clicked: boolean = false
  openSidenav = new BehaviorSubject(null)
  updateTable = new BehaviorSubject(null)
  isMetadataOpen: boolean = false
  showFirstImport: boolean = false
  organization: any
  groups: any
  loadedUsers: any
  importedSamples: any
  userLoading: boolean = false
  orgLoading: boolean = false
  public currentStepSubject = new BehaviorSubject<number>(0)
  isTableClicked: boolean = false
  elementSetName: any
  elementId: any
  public sidenavClosed = new BehaviorSubject<boolean>(false)
  isSidenavOpen: boolean = false
  isConfigError: boolean = false
  noUsers: boolean = false

  get currentStep(): number {
    return this.currentStepSubject.value
  }

  setCurrentStep(stepIndex: number): void {
    this.currentStepSubject.next(stepIndex)
  }

  csvRecords$ = this.csvRecords.asObservable()

  url: any
  dataSource: any
  sortState: any
  orderReverse: any = { setName: false, createdDate: false }
  clickedRow = signal("")

  private userUpdatedSubject = new Subject<void>()
  userUpdated$ = this.userUpdatedSubject.asObservable()

  announceUserUpdate() {
    this.userUpdatedSubject.next()
  }

  private csvParserUpdatedSubject = new Subject<void>()
  csvParserUpdated$ = this.csvParserUpdatedSubject.asObservable()

  announceParserUpdate() {
    this.showFirstImport = false
    this.csvParserUpdatedSubject.next()
  }

  private allOrgsUpdatedSubject = new Subject<void>()
  allOrgsUpdated$ = this.allOrgsUpdatedSubject.asObservable()

  announceOrgsUpdate() {
    this.allOrgsUpdatedSubject.next()
  }

  private versionUpdateSource = new Subject<void>()

  versionUpdate$ = this.versionUpdateSource.asObservable()

  updateVersion() {
    this.versionUpdateSource.next()
  }

  searchedSetID = new BehaviorSubject<any>(null)
  setNameNewImport = new BehaviorSubject(<any>"")

  private setNameNewImport$ = new BehaviorSubject<any>("")
  setNameNewFolder$ = this.setNameNewImport$.asObservable()

  setNameFolder(setNameNewImport: any) {
    this.setNameNewImport$.next(setNameNewImport)
  }

  transform(element: string) {
    // @ts-ignore
    const result = element
      .replace(/[0-9]/g, "")
      .replaceAll("_", " ")
      .replace(/([A-Z])/g, " $1")
      .trim()
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  getNextPortion(reset?: boolean, init?: boolean) {
    if (this.pending) return

    this.pending = true
    this.page += this.pageSize

    if (reset || init) {
      this.lastPage = false
      this.page = 0
      if (init) {
        this.selectedItems = []
        this.searchControl.setValue("")
      }
    }

    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    this.orderType = uuidPattern.test(this.searchControl.value)
      ? "id"
      : "set_name"

    let params = new HttpParams()
      .set("skip", String(this.page))
      .set("limit", String(this.pageSize))

    if (this.searchControl.value) {
      params = params.set("orderField", this.orderType)
      params = params.set("keyword", this.searchControl.value)
    } else if (this.sortState) {
      params = params.set("orderField", this.sortState)
    }
    if (this.sortState) {
      params = params.set(
        "orderReverse",
        String(this.orderReverse[this.sortState]),
      )
    }

    // TODO: this url should be dependant on user permissions
    this.url = "/list_sets/"

    this.http.get(this.url, { params }).subscribe((response: any) => {
      const data = response.data
      this.totalSizePage = response.total

      if (this.page + this.pageSize >= this.totalSizePage) {
        this.lastPage = true
      }

      if (reset || init) {
        if (init && data.length > 0) {
          this.selectedItems = [data[0]]
        }
        if (this.selectedItems[0]) {
          const selectedIndex = data.findIndex(
            (el: any) => el?.id === this.selectedItems[0].id,
          )
          if (selectedIndex > -1) {
            data.splice(selectedIndex, 1)
            data.unshift(this.selectedItems[0])
          }
        }
      }

      this.csvRecords.next(reset ? data : [...this.csvRecords.value, ...data])

      setTimeout(() => {
        this.pending = false
      }, 300)
    })
  }

  base64url(source: any) {
    let encodedSource = CryptoJS.enc.Base64.stringify(source)

    encodedSource = encodedSource.replace(/=+$/, "")

    encodedSource = encodedSource.replace(/\+/g, "-")
    encodedSource = encodedSource.replace(/\//g, "_")

    return encodedSource
  }

  encodeToken(payload: any) {
    var header = {
      alg: "HS256",
      typ: "JWT",
    }

    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
    var encodedHeader = this.base64url(stringifiedHeader)

    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload))
    var encodedData = this.base64url(stringifiedData)

    var token = encodedHeader + "." + encodedData
    return token
  }

  signToken(payload: any, key: string) {
    var secret = key
    let token: any = this.encodeToken(payload)

    var signature: any = CryptoJS.HmacSHA256(token, secret)
    signature = this.base64url(signature)

    var signedToken = token + "." + signature
    return signedToken
  }

  getGitbookURL() {
    const gitbookSignKey = environment.gitbook_privatekey
    const token = this.signToken({ data: "foobar" }, gitbookSignKey)
    const fileUrl = environment.users_guide
    const gitbookURL = `${fileUrl}/?jwt_token=${token}`
    return gitbookURL
  }

  reset() {
    this.sampleSetId.next("")
    this.csvRecords.next([])
    this.openSidenav.next(null)
    this.updateTable.next(null)
    this.searchedSetID.next(null)
    this.setNameNewImport.next("")
    this.setNameNewImport$.next("")

    this.page = 0
    this.pageSize = 50
    this.lastPage = false
    this.pending = false
    this.orderType = "set_name"
    this.totalSizePage = 0
    this.inputValue = ""
    this.selectedItems = []
    this.clicked = false
    this.isMetadataOpen = false
    this.showFirstImport = false
    this.userLoading = false
    this.orgLoading = false
    this.currentStepSubject.next(0)
    this.isTableClicked = false
    this.isSidenavOpen = false
    this.isConfigError = false
    this.noUsers = false

    this.searchControl.reset()
    this.sortControl.reset()

    this.organization = null
    this.groups = null
    this.loadedUsers = null
    this.importedSamples = null

    this.sortState = null
    this.orderReverse = { setName: false, createdDate: false }
  }
}
