import { Component, Inject, OnDestroy } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"
import { NavigationStart, Router } from "@angular/router"
import { Subscription } from "rxjs"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-custom-snackbar",
  templateUrl: "./custom-snackbar.component.html",
  styleUrls: ["./custom-snackbar.component.scss"],
})
export class CustomSnackbarComponent implements OnDestroy {
  private routerEventsSubscription!: Subscription

  constructor(
    public router: Router,
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    public commonService: CommonService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.snackBarRef.dismiss()
      }
    })
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe()
  }
}
