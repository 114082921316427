import { Component } from "@angular/core"
import { LocalStorageService } from "ngx-localstorage"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-current-organization",
  templateUrl: "./current-organization.component.html",
  styleUrls: ["./current-organization.component.scss"],
})
export class CurrentOrganizationComponent {
  orgId: any
  orgName: any
  logo: any
  totalAcc: any

  constructor(
    public commonService: CommonService,
    public authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.orgId = this.localStorageService.get("clickedRowId") as string
    this.orgName = this.localStorageService.get("clickedRowName") as string
    this.logo = this.localStorageService.get("clickedRowLogo") as string
    this.totalAcc = this.localStorageService.get("clickedRowTotalAcc") as string
  }
}
