<section class="bg-sample pt30px">
  <div class="content-about">
    <div class="left-container text-grey">
      <article class="res-w80">
        <div class="title-set mb30px">About the App</div>
        <!-- <h2>About the App</h2> -->
      </article>
      <article id="10.0.1" class="res-w80">
        <button class="download-btn" mat-button (click)="downloadFile()">
          Pyxis User Guide
        </button>

        <h2 class="title-version">Version {{ version }} (Actual)</h2>
        <!-- <span class="date">December 1, 2023</span><br> -->
        <span class="date" *ngIf="revisionDate">{{ revisionDate }}</span
        ><br />
        <span class="date" *ngIf="!revisionDate"
          >{{ today | date: "longDate" }} </span
        ><br />
      </article>
    </div>
    <div class="rightCol sideCol">
      <article class="container-column jc-end">
        <div class="card-basic position-fix-about">
          <span class="contact">Contact Support Team</span>
          <a class="email" href="mailto:{{ email }}">{{ email }}</a>
        </div>
      </article>
    </div>
  </div>
</section>
