import { Component, OnInit } from "@angular/core"
import { environment } from "../../../../../environments/environment"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  version = environment.version
  today = new Date()
  revisionDate = environment.revision_date
  email: string = "support@matterworks.ai"
  usersGuide = environment.users_guide

  constructor(public commonService: CommonService) {
    this.usersGuide = commonService.getGitbookURL()
  }

  ngOnInit(): void {
    console.log(this.revisionDate)
  }
  downloadFile() {
    window.open(this.usersGuide, "_blank")
  }
}
