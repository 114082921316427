<div
  *ngIf="
    router.url !== '/my-profile' &&
    router.url !== '/user-account' &&
    commonService.importedSamples?.length > 0 &&
    router.url !== '/accounts-org'
  "
  class="container-row-only row"
>
  <div class="column w80">
    <div class="bold">{{ data.name }}</div>
  </div>
  <div class="column">
    <button mat-button (click)="snackBarRef.dismissWithAction()">
      <span class="text-orange">View</span>
    </button>
  </div>
</div>

<div class="column" *ngIf="router.url == '/accounts-org'">
  <div class="container-row-only">
    <div class="column">
      <div class="bold">{{ data.name }}</div>
    </div>
    <button mat-button (click)="snackBarRef.dismissWithAction()">
      <span class="text-orange"> Close </span>
    </button>
  </div>
</div>

<div
  *ngIf="
    router.url == '/my-profile' ||
    router.url == '/user-account' ||
    router.url != '/accounts-org' ||
    commonService.importedSamples?.length == 0
  "
  class="container-row-only row"
>
  <div class="column w80" *ngIf="data.activeIcon">
    <div class="container-row-only">
      <img
        src="../../../../../assets/icons/info-red.svg"
        class="deactive-icon"
      />
      <div class="column">
        <div class="bold">{{ data.name }}</div>
        <div>
          {{ data.email }}
        </div>
      </div>
    </div>
  </div>
  <div class="column w80" *ngIf="data.arhiveIcon && !data.activeIcon">
    <div class="container-row-only">
      <img
        src="../../../../../assets/icons/arhive-snackbar.svg"
        class="deactive-icon"
      />
      <div class="column">
        <div class="bold">{{ data.name }}</div>
        <div>
          {{ data.email }}
        </div>
      </div>
    </div>
  </div>

  <div class="column w80" *ngIf="!data.activeIcon || !data.arhiveIcon">
    <div class="bold">{{ data.name }}</div>
  </div>
  <div class="column">
    <button mat-button (click)="snackBarRef.dismissWithAction()">
      <span class="text-orange"> Close </span>
    </button>
  </div>
</div>
