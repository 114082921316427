import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class ToggleService {
  sideNavToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null)
  constructor() {}
  public toggle(linkSample: any) {
    console.log("subject")

    return this.sideNavToggleSubject.next(linkSample)
  }
}
