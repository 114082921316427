import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { ViewportScroller } from "@angular/common"

@Component({
  selector: "app-release-notes",
  templateUrl: "./release-notes.component.html",
  styleUrls: ["./release-notes.component.scss"],
})
export class ReleaseNotesComponent {
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId)
  }

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {}

  scrollToContactTypes() {
    this.router.onSameUrlNavigation = "reload"
    this.router
      .navigate(["/release-notes"], { fragment: "contactTypes" })
      .finally(() => {
        this.router.onSameUrlNavigation = "ignore" // Restore config after navigation completes
      })
  }
}
