<div class="container-org">
  <div class="container-row-head">
    <div class="column logo-content">
      <div *ngIf="logo.includes('data:image')">
        <img src="{{ logo }}" class="logo-org" />
      </div>
    </div>
    <div class="column name-content">
      <span class="name-org">{{ orgName }}</span>
      <span class="code-org">US-EAST-1</span>
    </div>
    <div class="column user-content">
      <span class="user-account">User accounts</span>
      <span class="user-count">{{ totalAcc }}</span>
    </div>
    <div class="column user-content">
      <span class="user-account">Jobs executed</span>
      <span class="user-count">---</span>
    </div>
    <div class="column btn-end">
      <button type="button" mat-stroked-button color="accent">
        Inspector Mode
      </button>
    </div>
  </div>
  <div class="container-row-head">
    <div class="subnavbar">
      <button
        mat-button
        routerLink="/accounts-org"
        routerLinkActive="active-link"
        class="btn-subnav"
      >
        accounts
      </button>
      <button
        mat-button
        routerLink="/programs-setup"
        routerLinkActive="active-link"
        class="btn-subnav"
      >
        programs setup
      </button>
      <button
        mat-button
        routerLink="/logs-org"
        routerLinkActive="active-link"
        class="btn-subnav"
      >
        Logs
      </button>
      <button
        mat-button
        routerLink="/settings-org"
        routerLinkActive="active-link"
        class="btn-subnav"
      >
        settings
      </button>
    </div>
  </div>
</div>
