import { Component } from "@angular/core"
import { Location } from "@angular/common"

@Component({
  selector: "app-error500",
  templateUrl: "./error500.component.html",
  styleUrls: ["./error500.component.scss"],
})
export class Error500Component {
  constructor(private location: Location) {}

  previous() {
    this.location.back()
  }
}
