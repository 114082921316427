import { TemplatePortal } from "@angular/cdk/portal"
import { HttpClient } from "@angular/common/http"
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  signal,
} from "@angular/core"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort, Sort } from "@angular/material/sort"
import { Router } from "@angular/router"
import * as moment from "moment"
import { OverlayRef } from "ngx-toastr"
import {
  Subject,
  BehaviorSubject,
  debounceTime,
  switchMap,
  of,
  interval,
  map,
  Subscription,
} from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { fromObservable } from "src/app/custom-mat-search/from-observable"
import { fromSignal } from "src/app/custom-mat-search/from-signal"
import { TooltipService } from "src/app/shared/services/tooltip.service"
import { TooltipDirective } from "../../programs/custom-directives/tooltip.directive"
import { CommonService } from "src/app/core/services/commonservice.service"
import { MatDialog } from "@angular/material/dialog"
import { CreateOrgDialogComponent } from "../../../components/create-org-dialog/create-org-dialog.component"
import { LocalStorageService } from "ngx-localstorage"

@Component({
  selector: "app-all-organization",
  templateUrl: "./all-organization.component.html",
  styleUrls: ["./all-organization.component.scss"],
})
export class AllOrganizationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort!: MatSort
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChildren(TooltipDirective) tooltips!: QueryList<TooltipDirective>

  isLoading: boolean = true
  allJobsLoaded: boolean = false

  displayedColumns: any[] = ["org_name", "total", "registered", "programs"]

  organizations: any
  searchSubject = new Subject<string>()

  searchInput = ""
  sortState = signal(["createdDate", true])
  private readonly queryS = signal("")
  private readonly loadingN = signal(true)

  searchedWord: any
  input: any
  today = new Date()
  autoRefresh: any
  loading = new BehaviorSubject(<boolean>true)
  page = 0
  pageSize = 50
  lastPage = false
  totalSizePage: any
  id: any
  pending = false
  updateSubscription: any
  jobsLimit = 10
  jobsSkip = 0
  totalJobs: any
  listJobs: any
  searchedValue: any
  downLoadStartValue: any = null
  startDownloading: boolean = false
  initialLoadedJobs: any
  isSearched: boolean = false
  isSorted: boolean = false
  orderReverse: boolean = true
  isNextPage: boolean = false
  DownLoadFile: any
  private updateOrgsSubscription!: Subscription

  private overlayRef: OverlayRef | null = null
  @ViewChild("tooltipContent", { static: true })
  tooltipContent!: TemplateRef<any>
  showTooltip = false
  tooltipVisibility: boolean[] = []
  resultDate: any

  private readonly query = signal("")

  constructor(
    public authService: AuthService,
    public commonService: CommonService,
    public http: HttpClient,
    private router: Router,
    public tooltipService: TooltipService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.loading.next(true)
    this.getOrganizations()
    this.setupSearchListener()
    this.updateOrgsSubscription = this.commonService.allOrgsUpdated$.subscribe(
      () => {
        this.getOrganizations()
      },
    )
  }

  ngAfterViewInit() {}

  setupSearchListener() {
    this.searchSubject
      .pipe(
        debounceTime(800),
        switchMap((keyword) => {
          return this.searchOrganizations(keyword)
        }),
      )
      .subscribe((organizations) => {
        this.organizations = organizations
        this.isLoading = false
      })
  }

  searchOrganizations(keyword: string) {
    const url = keyword.trim()
      ? `/list_orgs/?orderField=id&keyword=${keyword}&orderReverse=false`
      : "/list_orgs/?orderField=id&orderReverse=false"
    return this.http.get(url).pipe(map((response: any) => response.data))
  }

  changeQuery(query: string): void {
    this.isLoading = true
    this.searchSubject.next(query)
  }

  announceSortChange(sortState: Sort) {}

  getOrganizations(url?: any) {
    this.http
      .get("/list_orgs/?orderField=id&orderReverse=false")
      .subscribe((response: any) => {
        if (response) {
          this.organizations = response.data
          this.isLoading = false
        }
      })
  }

  incrementTimeBy30Minutes(inputDate: Date) {
    this.resultDate = new Date(inputDate)
    this.resultDate.setMinutes(this.resultDate.getMinutes() + 30)
    this.startCountdown()
    return this.resultDate
  }
  timeLeft: any
  interval: any
  startCountdown() {
    this.timeLeft = this.resultDate
    this.interval = setInterval(() => {
      this.timeLeft -= 30
    }, 30000)
  }
  pageChanged(event: any) {}

  onRowClicked(row: any) {
    this.localStorageService.set("clickedRowId", row.id)
    this.localStorageService.set("clickedRowName", row.name)
    this.localStorageService.set("clickedRowNameId", row.nameId)
    this.localStorageService.set("clickedRowLogo", row.logo)
    this.localStorageService.set("clickedRowTotalAcc", row.totalAccounts)
    this.commonService.clickedRow.set(row.id)
    this.router.navigate(["programs-setup"])
  }
  openCreateOrgDialog() {
    const dialogRef = this.dialog.open(CreateOrgDialogComponent, {
      width: "450px",
      // height: '721px',
      minHeight: "500px",
      hasBackdrop: true,
      panelClass: "add-new-org",
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }

  getLocaleDateOfDay(utcDateTime: any) {
    let local = new Date(utcDateTime).toLocaleDateString()
    return local
  }

  ngOnDestroy(): void {
    this.updateOrgsSubscription.unsubscribe()
  }
}
