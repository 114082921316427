<section class="bg-sample pt30px">
  <div class="content container-row-response">
    <div class="left-container-response text-grey">
      <div *ngIf="isAllLoad && isTotalLoad && isAllUserLoad">
        <article>
          <div class="title-set mb30px">My Organization</div>
          <div class="container-row">
            <div class="column name-organization">
              <span>Organization Name</span>
              <div *ngFor="let group of this.authService.groups">
                <span class="bold">{{
                  this.authService.role == 2
                    ? group["name"] + " (Platform Admin)"
                    : group["name"]
                }}</span>
              </div>
            </div>
            <div class="column">
              <span>
                {{
                  this.authService.role == 2
                    ? "Total Users (Across All Organizations)"
                    : "Total Users"
                }}</span
              >
              <span class="bold">{{ totalCountUsers }}</span>
            </div>
            <div class="rightColIpad">
              <article class="container-column jc-end">
                <div class="card-basic my-card-ipad">
                  <mat-list role="list" class="list-right-ipad">
                    <mat-list-item role="listitem" class="clicked"
                      ><a routerLink="/user-account"
                        >Organization Info</a
                      ></mat-list-item
                    >
                    <mat-list-item role="listitem"
                      ><a routerLink="/my-profile">My Profile</a></mat-list-item
                    >
                  </mat-list>
                </div>
              </article>
            </div>
          </div>
        </article>
        <article class="mine-article border16 card-half mt60px mb110">
          <div class="container-row space-between">
            <div class="w60">
              <span class="title-manage">Manage User Accounts</span><br />
            </div>
            <div class="gap15 content-search-add">
              <mat-form-field class="input-new" appearance="outline">
                <mat-icon matPrefix class="icon-grey">search</mat-icon>
                <input
                  matInput
                  placeholder="Search"
                  [(ngModel)]="searchInput"
                  (input)="changeQuery(searchInput)"
                />
              </mat-form-field>
              <button
                mat-flat-button
                matPrefix
                type="button"
                color="accent"
                class="text-white user-manage-btn import-btn"
                (click)="openAddNewUser()"
              >
                Add New User
              </button>
            </div>
          </div>
          <div>
            <table
              mat-table
              [dataSource]="listUsers"
              matSort
              class="mat-elevation-z8 csv-parser-content table-users"
              (matSortChange)="announceSortChange($event)"
              matSortDisableClear
            >
              <ng-container matColumnDef="email">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Sort by email"
                  start="desc"
                >
                  Account
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.enabled ? '' : 'opacity'"
                >
                  <span class="truncate-text">{{ element.email }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.enabled ? '' : 'opacity'"
                >
                  <ng-container [ngSwitch]="element.role">
                    <div *ngSwitchCase="0">User</div>
                    <div *ngSwitchCase="1">Admin</div>

                    <div *ngSwitchCase="2">Super Admin</div>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="organization">
                <th mat-header-cell *matHeaderCellDef>
                  <span
                    *ngIf="
                      this.authService.permissions?.includes(
                        'read:platform_users'
                      )
                    "
                  >
                    User groups
                  </span>
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.enabled ? '' : 'opacity'"
                >
                  <div
                    *ngIf="
                      this.authService.permissions?.includes(
                        'read:platform_users'
                      )
                    "
                  >
                    <div *ngFor="let group of element.groups">
                      {{ group.name }}
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="lastSession">
                <th mat-header-cell *matHeaderCellDef>Last Accessed</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.enabled ? '' : 'opacity'"
                >
                  <div *ngIf="element.lastAccessed">
                    {{ element.lastAccessed | date: "short" }}
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="element.enabled ? '' : 'opacity'"
                >
                  <div *ngIf="element.enabled == true" class="status-info">
                    <img src="../../../../../assets/icons/check.svg" />
                    <span>Active</span>
                  </div>
                  <div *ngIf="element.enabled == false" class="status-info">
                    <img src="../../../../../assets/icons/arhive.svg" />
                    <span>Archive</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.enabled == true">
                    <button
                      mat-button
                      (click)="
                        openEditUserDialog(
                          element.email,
                          element.role,
                          element.id,
                          element.givenName,
                          element.middleName,
                          element.familyName,
                          element.bucket
                        )
                      "
                    >
                      Edit
                    </button>
                  </div>
                  <div *ngIf="element.enabled == false">
                    <button
                      mat-button
                      (click)="
                        openReactivetedUserDialog(
                          element.email,
                          element.role,
                          element.id,
                          element.givenName,
                          element.middleName,
                          element.familyName,
                          element.bucket
                        )
                      "
                    >
                      Edit
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">
                  No data foun matching filtered value "{{ searchedValue }}"
                </td>
              </tr>
            </table>
          </div>
          <mat-paginator
            class="paginator"
            [length]="totalUser"
            [pageSize]="usersLimit"
            [pageSizeOptions]="[10]"
            (page)="pageChanged($event)"
          ></mat-paginator>

          <!-- </cdk-virtual-scroll-viewport> -->
        </article>
      </div>
      <div *ngIf="!isAllLoad || !isTotalLoad || !isAllUserLoad">
        <div
          class="container-spinner"
          *ngIf="!this.commonService.isConfigError"
        >
          <div class="spinner">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
      <div *ngIf="isSearched || isSorted">
        <div
          class="container-spinner test"
          *ngIf="!this.commonService.isConfigError"
        >
          <div class="spinner">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="rightCol">
      <article class="container-column jc-end">
        <div class="card-basic my-card position-fix-about">
          <mat-list role="list" class="list-right">
            <mat-list-item role="listitem" class="clicked"
              ><a routerLink="/user-account"
                >Organization Info</a
              ></mat-list-item
            >
            <mat-list-item role="listitem"
              ><a routerLink="/my-profile">My Profile</a></mat-list-item
            >
          </mat-list>
        </div>
      </article>
    </div>
  </div>
</section>
