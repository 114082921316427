import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-snackbar-pending-upload",
  templateUrl: "./snackbar-pending-upload.component.html",
  styleUrls: ["./snackbar-pending-upload.component.scss"],
})
export class SnackbarPendingUploadComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarPendingUploadComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
