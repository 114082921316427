<div class="container-row">
  <div class="column w80">
    <div class="normal">{{ data.text }}</div>
  </div>
  <div class="btn-close">
    <button mat-button (click)="snackBarRef.dismiss()">
      <span class="text-orange">OK</span>
    </button>
  </div>
</div>
