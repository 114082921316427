import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-custom-snackbar-user",
  templateUrl: "./custom-snackbar-user.component.html",
  styleUrls: ["./custom-snackbar-user.component.scss"],
})
export class CustomSnackbarUserComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarUserComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
