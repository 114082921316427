<app-navbar
  *ngIf="
    router.url !== '/home' &&
    router.url !== '/pass-changed' &&
    router.url !== '/forgot-pass' &&
    router.url !== '/forgot-pass/' + this.savedEmail &&
    router.url !== '/login' &&
    router.url !== '/register' &&
    (authService.isAuthenticated$ | async)
  "
>
</app-navbar>

<!-- <app-navbar *ngIf="showHeader"></app-navbar> -->
<main>
  <router-outlet *ngIf="isLoggedIn"></router-outlet>
</main>
