import { HttpClient } from "@angular/common/http"
import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import { ActivatedRoute, Router } from "@angular/router"
import { TextImportDialogComponent } from "../dropzone/text-import-dialog/text-import-dialog.component"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-errors",
  templateUrl: "./errors.component.html",
  styleUrls: ["./errors.component.scss"],
})
export class ErrorsComponent implements OnInit {
  status: string = ""
  loginMessage: string = ""
  errorMessage: any
  @Output() tryAgainClicked: EventEmitter<void> = new EventEmitter<void>()
  currentRoute: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dialog: MatDialog,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<ErrorsComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentRoute = this.router.url
    if (this.currentRoute !== "/metadata") {
      this.dialogRef.backdropClick().subscribe(() => {
        this.onTryAgainClick()
        this.commonService.clicked = !this.commonService.clicked
      })
    }
  }

  ngOnInit() {
    this.errorMessage = Object.values(this.data)
    this.status = this.activatedRoute.snapshot.queryParams["status"]
    this.loginMessage = this.activatedRoute.snapshot.queryParams["err.message"]
  }

  enableRun() {
    this.commonService.clicked = false
  }

  onTryAgainClick() {
    this.commonService.clicked = !this.commonService.clicked
    this.tryAgainClicked.emit()

    if (this.currentRoute === "/metadata") {
      this.dialogRef.close()
    } else if (this.currentRoute === "/user-account") {
      this.commonService.userLoading = !this.commonService.userLoading
      this.dialogRef.close()
    } else if (this.currentRoute === "/all-organizations") {
      this.dialogRef.close()
    } else {
      this.dialog.closeAll()
    }
  }
}
