import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
} from "@angular/router"
import { CognitoUserPool } from "amazon-cognito-identity-js"
import { Observable } from "rxjs"
import { AuthService } from "../services/auth.service"
import { environment } from "../../../environments/environment"
import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const poolData = {
      UserPoolId: environment.cognito.userPoolId, // Your user pool id here
      ClientId: environment.cognito.userPoolWebClientId,
    }

    return new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser != null) {
        cognitoUser.getSession((err: any, session: any) => {
          if (err) {
            reject(err)
            return
          }

          const cognitoSession = session.getIdToken().payload["custom:Role"]
          if (cognitoSession == 0) {
            resolve(this.router.parseUrl("/metadata"))
          } else {
            resolve(true)
          }
        })
      } else {
        resolve(true) // or redirect somewhere else if the user isn't logged in
      }
    })
  }
}
