import { SelectionModel } from "@angular/cdk/collections"
import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import { CommonService } from "../../../../../core/services/commonservice.service"
import { WorkerserviceService } from "../../../../../core/services/workerservice.service"
import { DialogData, Elements } from "../../../models/text-import.interface"
import { ErrorsComponent } from "../../errors/errors.component"
import { MatStepper } from "@angular/material/stepper"
import { log } from "console"
import { ToastrService } from "ngx-toastr"
import { catchError } from "rxjs/internal/operators/catchError"
import { CustomSnackbarComponent } from "../../custom-snackbar/custom-snackbar.component"
import { ToggleService } from "src/app/shared/services/toggle.service"

@Component({
  selector: "app-text-import-dialog",
  templateUrl: "./text-import-dialog.component.html",
  styleUrls: ["./text-import-dialog.component.scss"],
})
export class TextImportDialogComponent {
  @ViewChild("stepper")
  stepper: any = ElementRef
  @ViewChild("modValue")
  modValue: any = ElementRef
  active = false
  isLinear = true
  displayedColumns: any[] = [
    "select",
    "importingFileColumnName",
    "dataType",
    "pyxisColumn",
  ]
  options = ["Text", "Link"]
  samples: any[] = []
  dataSource = new MatTableDataSource<any>([])
  selection = new SelectionModel<any>(true, [])
  lastAddedFile: any
  setName: any
  sampleIdlink: any
  email: any
  createdDate: any
  allFiles: any = []
  columnNames: any = {}
  id: string = ""
  isLoading = false
  isFailed: boolean = false
  statusClass: any = "not-active"
  isEditable = false
  isButtonClicked = false

  rawFile: any

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  sampleSetConfigForm = new FormGroup({
    sampleFileField: new FormControl("", Validators.required),
    sampleNameField: new FormControl("", Validators.required),
    setId: new FormControl(this.id),
    setNameField: new FormControl("", Validators.required),
  })
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<TextImportDialogComponent>,
    public workerService: WorkerserviceService,
    public commonService: CommonService,
    public toggleService: ToggleService,
    private http: HttpClient,
    public snackbar: MatSnackBar,

    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {}
  ngOnInit(): void {
    // this.dialogRef.afterClosed().subscribe((res: any) => {
    //   if (res) {
    //     this.workerService.callWorker({
    //       storage: {
    //         db: 'csvDB',
    //         id: this.id,
    //         setName: this.setName,
    //         samples: this.samples,
    //         columnNames: this.columnNames,
    //         email: this.email,
    //         createdDate: this.createdDate
    //       }
    //     });
    //     this.router.navigate(["/metadata"]);
    //     this.commonService.trigger.next(true);
    //   }
    // })
  }

  getName(lastAddedFile: any) {
    let name
    if (Array.isArray(lastAddedFile)) {
      name =
        lastAddedFile[0].name.indexOf(".csv") > -1
          ? lastAddedFile[0].name.split(".csv")[0]
          : lastAddedFile[0].name
    } else {
      name =
        lastAddedFile.name.indexOf(".csv") > -1
          ? lastAddedFile.name.split(".csv")[0]
          : lastAddedFile.name
    }
    return name
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    this.dataSource.data.map((row) => {
      if (this.columnNames[row.importCol]) {
        delete this.columnNames[row.importCol]
      } else {
        this.columnNames[row.importCol] = row.name
      }
    })
    return numSelected === numRows
  }

  toggleAllRows() {
    if (this.selection.selected.length === 0) {
      this.selection.select(...this.dataSource.data)
    } else if (
      this.selection.selected.length > 0 &&
      this.selection.selected.length < this.dataSource.data.length
    ) {
      this.selection.select(...this.dataSource.data)
    } else if (this.selection.selected.length === this.dataSource.data.length) {
      this.selection.clear()
    }
  }

  select(row: any) {
    this.selection.toggle(row)
    if (this.columnNames[row.importCol]) {
      delete this.columnNames[row.importCol]
    } else {
      this.columnNames[row.importCol] = row.name
    }
  }

  onSelect(event: any) {
    this.allFiles.push(event.addedFiles[0])
    this.active = true
    this.statusClass = "active"
  }

  onClick(stepper: MatStepper) {
    this.isButtonClicked = true
    this.active = true
    let formData = new FormData()
    formData.append("file", this.allFiles[0])
    this.http.post(`/upload_set_csv/`, formData).subscribe((response: any) => {
      // if (Object.keys(response).length === 0) {
      //   this.dialogRef.close();
      //   this.dialog.open(ErrorsComponent, {
      //     width: '360px',
      //     height: 'auto',
      //     hasBackdrop: true,
      //     panelClass: 'dialogJobRight',
      //   });
      // } else {
      let elements: Elements[] = []
      Object.keys(response[0].samples[0].attributes).map((key) => {
        elements.push({
          importCol: response[0].samples[0].attributes[key].name,
          type: "Text",
          name: response[0].samples[0].attributes[key].name,
        })
      })
      // console.log('++++ error import', response)
      this.dataSource.data = elements
      this.lastAddedFile = this.allFiles[this.allFiles.length - 1]
      this.id = response[0].id
      this.setName = response[0].set_name
      this.sampleIdlink = response[0].samples[0].attributes[2].content
      this.email = response[0].email
      // this.rawFile = response[0].samples[0].attributes[2].content
      this.createdDate = response[0].createdDate
      this.samples.push(...response[0].samples)
      this.dataSource.data.map((row) => {
        if (this.columnNames[row.importCol]) {
          delete this.columnNames[row.importCol]
        } else {
          this.columnNames[row.importCol] = row.name
        }
      })
      stepper.next()
      this.toggleAllRows()
      // }
    })
  }
  success() {
    this.sampleSetConfigForm.controls.setId.setValue(this.id)
    let body: any = this.sampleSetConfigForm.value

    Object.keys(body).forEach((key) => {
      body[key] = body[key].replaceAll("  ", " ")
    })
    this.dialogRef.close(body)
  }

  onRemove(event: any) {
    this.allFiles = []
    this.active = false
    this.isButtonClicked = false
  }

  // changeSelection(event: any, el: any) {
  //   el.type = event.value
  //   if (this.columnNames[el.importCol]) {
  //     this.columnNames[el.importCol].type = event.value;
  //   }
  // }
}
