import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog"
import { NgxDropzoneModule } from "ngx-dropzone"
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader"
import { SharedModule } from "src/app/shared/shared.module"
import { AppRoutingModule } from "../../app-routing.module"
import { MaterialModule } from "../../material.module"
import { AddNewUserDialogComponent } from "./components/add-new-user-dialog/add-new-user-dialog.component"
import { CreateOrgDialogComponent } from "./components/create-org-dialog/create-org-dialog.component"
import { CsvParserComponent } from "./components/csv-parser/csv-parser.component"
import { CsvTableComponent } from "./components/csv-table/csv-table.component"
import { CustomSnackbarArhiveComponent } from "./components/custom-snackbar-arhive/custom-snackbar-arhive.component"
import { CustomSnackbarJobComponent } from "./components/custom-snackbar-job/custom-snackbar-job.component"
import { CustomSnackbarMetadataComponent } from "./components/custom-snackbar-metadata/custom-snackbar-metadata.component"
import { CustomSnackbarUserComponent } from "./components/custom-snackbar-user/custom-snackbar-user.component"
import { CustomSnackbarComponent } from "./components/custom-snackbar/custom-snackbar.component"
import { DeleteUserDialogComponent } from "./components/delete-user-dialog/delete-user-dialog.component"
import { Dialog403Component } from "./components/dialog403/dialog403.component"
import { DropzoneComponent } from "./components/dropzone/dropzone.component"
import { TextImportDialogComponent } from "./components/dropzone/text-import-dialog/text-import-dialog.component"
import { EditRecoverUserDialogComponent } from "./components/edit-recover-user-dialog/edit-recover-user-dialog.component"
import { EditUserDialogComponent } from "./components/edit-user-dialog/edit-user-dialog.component"
import { Error404Component } from "./components/error404/error404.component"
import { Error500Component } from "./components/error500/error500.component"
import { ErrorsComponent } from "./components/errors/errors.component"
import { NoJobResultComponent } from "./components/no-job-result/no-job-result.component"
import { RecoverUserDialogComponent } from "./components/recover-user-dialog/recover-user-dialog.component"
import { TargetedAbsoluteDialogComponent } from "./components/targeted-absolute-dialog/targeted-absolute-dialog.component"
import { FeatureComponent } from "./feature.component"
import { AboutComponent } from "./pages/about/about.component"
import { CheckEmailComponent } from "./pages/check-email/check-email.component"
import { FPassEmailComponent } from "./pages/cognito-forgot/f-pass-email/f-pass-email.component"
import { FPassComponent } from "./pages/cognito-forgot/f-pass/f-pass.component"
import { EditMyProfileComponent } from "./pages/edit-my-profile/edit-my-profile.component"
import { RegisterComponent } from "./pages/forgot/register/register.component"
import { HomeModule } from "./pages/home/home.module"
import { LoginComponent } from "./pages/login/login.component"
import { MetadataComponent } from "./pages/metadata/metadata.component"
import { MyProfileComponent } from "./pages/my-profile/my-profile.component"
import { PassChangedComponent } from "./pages/pass-changed/pass-changed.component"
import { ProgramsModule } from "./pages/programs/programs.module"
import { ReleaseNotesComponent } from "./pages/release-notes/release-notes.component"
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component"
import { TermsComponent } from "./pages/terms/terms.component"
import { TryAgainComponent } from "./pages/try-again/try-again.component"
import { UserAccountComponent } from "./pages/user-account/user-account.component"
// import { TruncatePipe } from './pipes/truncate.pipe';
import { ClipboardModule } from "@angular/cdk/clipboard"
import { MatButtonModule } from "@angular/material/button"
import { MatDialogModule } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"
import { ConfirmDeleteDialogComponent } from "./components/confirm-delete-dialog/confirm-delete-dialog.component"
import { CustomSnackbarConfigErrComponent } from "./components/custom-snackbar-config-err/custom-snackbar-config-err.component"
import { SnackbarCreateOrgComponent } from "./components/snackbar-create-org/snackbar-create-org.component"
import { SnackbarDropzoneComponent } from "./components/snackbar-dropzone/snackbar-dropzone.component"
import { SnackbarOrgsComponent } from "./components/snackbar-orgs/snackbar-orgs.component"
import { SnackbarPendingUploadComponent } from "./components/snackbar-pending-upload/snackbar-pending-upload.component"
import { SnackbarUploadComponent } from "./components/snackbar-upload/snackbar-upload.component"

// app/app-routing.module

@NgModule({
  declarations: [
    ErrorsComponent,
    FeatureComponent,
    CsvParserComponent,
    CsvTableComponent,
    DropzoneComponent,
    TextImportDialogComponent,
    LoginComponent,
    RegisterComponent,
    CheckEmailComponent,
    PassChangedComponent,
    ReleaseNotesComponent,
    AboutComponent,
    ErrorsComponent,
    FPassComponent,
    FPassEmailComponent,
    Error404Component,
    Error500Component,
    Dialog403Component,
    TryAgainComponent,
    NoJobResultComponent,
    CustomSnackbarComponent,
    CustomSnackbarJobComponent,
    CustomSnackbarMetadataComponent,
    UserAccountComponent,
    MyProfileComponent,
    MetadataComponent,
    AddNewUserDialogComponent,
    EditUserDialogComponent,
    EditMyProfileComponent,
    DeleteUserDialogComponent,
    CustomSnackbarUserComponent,
    TermsComponent,
    TermsAndConditionsComponent,
    RecoverUserDialogComponent,
    EditRecoverUserDialogComponent,
    CustomSnackbarArhiveComponent,
    TargetedAbsoluteDialogComponent,
    CreateOrgDialogComponent,
    SnackbarDropzoneComponent,
    SnackbarUploadComponent,
    SnackbarPendingUploadComponent,
    CustomSnackbarConfigErrComponent,
    SnackbarOrgsComponent,
    SnackbarCreateOrgComponent,
    // TruncatePipe
    ConfirmDeleteDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HomeModule,
    ProgramsModule,
    NgxDropzoneModule,
    SharedModule,
    ClipboardModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        // extendsFromRoot: true,
        height: "56px",
      },
    }),
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    ErrorsComponent,
    FeatureComponent,
    CsvParserComponent,
    CsvTableComponent,
    DropzoneComponent,
    TextImportDialogComponent,
    LoginComponent,
    RegisterComponent,
    CheckEmailComponent,
    PassChangedComponent,
    AboutComponent,
    ErrorsComponent,
    FPassComponent,
    FPassEmailComponent,
    Error404Component,
    UserAccountComponent,
    MetadataComponent,
    MyProfileComponent,
    AddNewUserDialogComponent,
    EditMyProfileComponent,
    DeleteUserDialogComponent,
    TermsComponent,
    TermsAndConditionsComponent,
    TargetedAbsoluteDialogComponent,
    CreateOrgDialogComponent,
    ConfirmDeleteDialogComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeatureModule {}
