<section class="container-row-response bg-login" *ngIf="showCheckMssg">
  <div class="w50-response-hide"></div>
  <div class="w50-response start-flex">
    <mat-card appearance="outlined" class="center-flex w50 card-login">
      <div class="brand">
        <img src="../../../assets/images/logo-full.png" />
      </div>
      <span class="title-login">Check Your Email</span>
      <p class="forgot-text">
        A verification code has been sent to
        <span class="bold"> {{ email }}</span>
        <button
          mat-flat-button
          type="submit"
          color="warn"
          class="ptpb w100 h50 mt60px log-btn"
          (click)="showHideCheckMssg()"
        >
          Change password
        </button>
      </p>
    </mat-card>
  </div>
</section>

<!-- ///////////////////////  -->
<!-- <section class="container-row bg-login" *ngIf="!showCheckMssg"> -->
<section class="container-row-response bg-login" *ngIf="!showCheckMssg">
  <div class="w50-response-hide"></div>
  <div class="w50-response start-flex">
    <mat-card appearance="outlined" class="center-flex w50 card-login">
      <div class="content-login">
        <form
          class="form-signin form-login"
          method="POST"
          action="#"
          role="form"
        >
          <div class="brand">
            <img src="../../../assets/images/logo-full.png" />
          </div>
          <span class="title-login">Create New Password</span>
          <!-- <div *ngIf="errorMessage!=null" class="alert alert-danger">
                        {{ errorMessage }}
                    </div> -->
          <mat-form-field appearance="outline">
            <mat-label>Verification Code</mat-label>
            <input
              matInput
              id="verificationCode"
              type="verificationCode"
              maxlength="50"
              class="form-control"
              [(ngModel)]="verificationCode"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <!-- <mat-error *ngIf="errorMessage != null" class="errors-verification-code">
                        <span>{{ errorMessage }}</span><br>                        
                    </mat-error> -->
          <mat-error
            *ngIf="
              errorMessage ==
              'Invalid verification code provided, please try again.'
            "
            class="errors-verification-code"
          >
            <span>{{ errorMessage }}</span
            ><br />
          </mat-error>
          <mat-error
            *ngIf="
              errorMessage ==
              'Attempt limit exceeded, please try after some time.'
            "
            class="errors-verification-code"
          >
            <span>{{ errorMessage }}</span
            ><br />
          </mat-error>
          <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input
              matInput
              id="newPassword"
              type="password"
              maxlength="25"
              class="form-control"
              length="40"
              [(ngModel)]="password"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <mat-error
            *ngIf="errorMessage == '1 validation error detected'"
            class="errors"
          >
            <!-- <span>{{ errorMessage }}</span><br> -->
            <span>Password does not match the security policy. </span>
            <ul class="errors-ul">
              <li>More than 8 characters</li>
              <li>At least one special character {{ specialCharacters }}</li>
              <li>At least one number</li>
              <li>Must contain uppercase letter</li>
            </ul>
          </mat-error>

          <button
            (click)="onNext()"
            mat-flat-button
            id="signupSubmit"
            type="submit"
            color="warn"
            class="ptpb w100 h50 log-btn"
            [disabled]="!verificationCode || !password"
          >
            Reset Password
          </button>
        </form>
      </div>
    </mat-card>
  </div>
</section>

<!-- ////////////////  -->
<!-- <section class="container-row bg-login" *ngIf="!showCheckMssg">
    <div class="w50"></div>
    <div class="w50 start-flex">
        <mat-card appearance="outlined" class="center-flex w50 card-login">
            <div class="content-login">
                <form class="form-signin form-login" (submit)="onNext()" [formGroup]="changeForm" method="POST"
                    action="#" role="form">
                    <div class="brand">
                        <img src="../../../assets/images/logo-full.png">
                    </div>
                    <span class="title-login">Create New Password</span>

                    <mat-form-field appearance="outline">
                        <mat-label>Verification Code</mat-label>
                        <input matInput formControlName="verificationCode" ngModel type="verificationCode"  placeholder="Verification code" autofocus required>
                    </mat-form-field>
                    <mat-error
                        *ngIf="changeForm.controls['verificationCode'].errors && changeForm.controls['verificationCode'].errors['verificationCode'] && (changeForm.controls['verificationCode'].dirty || changeForm.controls['verificationCode'].touched)"
                        class="error-msg">
                        Verification code not valid
                    </mat-error>
                    <mat-error
                        *ngIf="changeForm.controls['verificationCode'].errors && changeForm.controls['verificationCode'].errors['required'] && (changeForm.controls['verificationCode'].dirty || changeForm.controls['verificationCode'].touched)"
                        class="error-msg">
                        Verification code is required
                    </mat-error>
                    <mat-form-field appearance="outline">
                        <mat-label>New Password</mat-label>
                        <input matInput formControlName="password" ngModel type="password" minlength="8" required
                            name="password" placeholder="Password" id="newPassword">
                    </mat-form-field>
                    <mat-error
                        *ngIf="changeForm.controls['password'].errors && changeForm.controls['password'].errors['password'] && (changeForm.controls['password'].dirty || changeForm.controls['password'].touched)"
                        class="error-msg">
                        Password not valid
                    </mat-error>
                    <mat-error
                        *ngIf="changeForm.controls['password'].errors && changeForm.controls['password'].errors['required'] && (changeForm.controls['password'].dirty || changeForm.controls['password'].touched)"
                        class="error-msg">
                        Password is required
                    </mat-error>
                    <button mat-flat-button id="signupSubmit" type="submit" color="warn"
                        class="ptpb w100 h50 log-btn">
                        Reset Password
                    </button>
                </form>
            </div>
        </mat-card>
    </div>
</section> -->
