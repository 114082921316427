import { Component, OnDestroy, OnInit } from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import { Router, ActivatedRoute } from "@angular/router"
import { UserLoginService } from "../../../../../../app/core/services/user-login.service"
import { CognitoCallback } from "../../../../../core/services/cognito-forgot.service"

@Component({
  selector: "app-f-pass-email",
  templateUrl: "./f-pass-email.component.html",
  styleUrls: ["./f-pass-email.component.scss"],
})
export class FPassEmailComponent implements CognitoCallback, OnInit, OnDestroy {
  verificationCode: string = ""
  email: string = ""
  password: string = ""
  errorMessage: string = ""
  private sub: any
  showCheckMssg: boolean = true

  changeForm!: FormGroup
  specialCharacters: string = "!@#$%^&*()_+\\-=[]{};':\"\\\\|,.<>/?"
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public userService: UserLoginService,
  ) {}

  ngOnInit() {
    this.buildForm()

    this.sub = this.route.params.subscribe((params) => {
      this.email = params["email"]
    })

    //@ts-ignore
    this.errorMessage = null
  }

  private buildForm() {
    this.changeForm = this.formBuilder.group({
      verificationCode: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    })
    this.changeForm.controls["verificationCode"].valueChanges.subscribe(
      (res) => {
        if (res) {
          if (
            !(
              this.changeForm.controls["password"].hasError("pattern") ||
              this.changeForm.controls["password"].hasError("required")
            )
          ) {
            this.changeForm.controls["password"].setErrors(null)
          }
        }
      },
    )
    this.changeForm.controls["password"].valueChanges.subscribe((res) => {
      if (res) {
        if (
          !(
            this.changeForm.controls["verificationCode"].hasError("pattern") ||
            this.changeForm.controls["verificationCode"].hasError("required")
          )
        ) {
          this.changeForm.controls["verificationCode"].setErrors(null)
        }
      }
    })
  }
  showHideCheckMssg() {
    this.showCheckMssg = !this.showCheckMssg
  }
  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  onNext() {
    //@ts-ignore
    this.errorMessage = null
    this.userService.confirmNewPassword(
      this.email,
      this.verificationCode,
      this.password,
      this,
    )
  }

  cognitoCallback(message: string) {
    if (message == null) {
      this.router.navigate(["/pass-changed"])
    } else if (
      message == "Invalid verification code provided, please try again."
    ) {
      this.errorMessage =
        "Invalid verification code provided, please try again."
    } else if (
      message == "Attempt limit exceeded, please try after some time."
    ) {
      this.errorMessage = "Attempt limit exceeded, please try after some time."
    } else {
      this.errorMessage = "1 validation error detected"
    }
  }
}
