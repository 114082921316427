<section class="container-add">
  <div class="content-add-org">
    <div class="closing-btn">
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="!commonService.orgLoading">
      <div class="title-add-org">Create Organization</div>
      <div class="subtitle-org">Organization Info</div>
      <form
        [formGroup]="addNewOrgForm"
        (ngSubmit)="addNewOrg()"
        class="form-add-new-org"
      >
        <div class="container-row-only">
          <mat-form-field appearance="outline" class="w100">
            <mat-label>Organization Name</mat-label>
            <input matInput type="text" formControlName="name" required />
            <mat-error
              *ngIf="addNewOrgForm.controls['name'].hasError('required')"
              >Organization Name is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="container-row-only row-upload">
          <div class="container-btn-upload">
            <input
              id="fileInput"
              type="file"
              (change)="onFileSelected($event)"
              formControlName="logo"
              accept="image/jpeg, .jpeg, image/png, .png, image/pjpeg, .jpg, image/svg+xml, .svg"
            />

            <label for="fileInput" class="custom-file-upload">
              Upload Logo
            </label>
          </div>
          <div class="text-upload">Max size 500 kb</div>
        </div>
        <div *ngIf="logoUploadSuccess" class="upload-success-message">
          Logo was uploaded successfully!
        </div>
        <div class="subtitle-org">Info</div>
        <div class="container-row-only">
          <mat-form-field appearance="outline" class="w100">
            <mat-label>Notes</mat-label>
            <input matInput type="text" formControlName="info" />
          </mat-form-field>
        </div>
        <div class="button-wrapper gap15 mt10px">
          <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
          <button
            mat-flat-button
            color="warn"
            class="text-white"
            type="submit"
            [disabled]="!addNewOrgForm.valid || this.creatingOrg"
          >
            Next
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="this.creatingOrg">
      <div class="container-spinner">
        <div class="spinner">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</section>
