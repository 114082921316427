<section class="bg-sample pt30px">
  <article class="container-column center-flex">
    <div class="container-error-text">
      <div class="title">
        Not found<br />
        404
      </div>
      <div class="text mb30px">Oops..., this page no longer exist :(</div>
      <div class="container-row gap15">
        <button
          mat-flat-button
          color="warn"
          class="ptpb h50 log-btn"
          routerLink="/"
        >
          Go to Home
        </button>
        <button
          mat-button
          color="accent"
          class="ptpb h50 log-btn"
          (click)="previous()"
        >
          Previous Page
        </button>
      </div>
    </div>
  </article>
</section>
