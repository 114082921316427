import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-snackbar-orgs",
  templateUrl: "./snackbar-orgs.component.html",
  styleUrls: ["./snackbar-orgs.component.scss"],
})
export class SnackbarOrgsComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarOrgsComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
