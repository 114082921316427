import { Component, Inject } from "@angular/core"
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar"

@Component({
  selector: "app-custom-snackbar-config-err",
  templateUrl: "./custom-snackbar-config-err.component.html",
  styleUrls: ["./custom-snackbar-config-err.component.scss"],
})
export class CustomSnackbarConfigErrComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarConfigErrComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
