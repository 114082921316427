import { CommonModule } from "@angular/common"
import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"
import { MaterialModule } from "src/app/material.module"
import { ErrorsComponent } from "../errors/errors.component"

@Component({
  selector: "app-dialog403",
  templateUrl: "./dialog403.component.html",
  styleUrls: ["./dialog403.component.scss"],
})
export class Dialog403Component {
  constructor(
    public dialogRef: MatDialogRef<ErrorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
