import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { LocalStorageService } from "ngx-localstorage"

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  infoOrg: any
  currentOrg: any
  isLoading: boolean = true
  selectedOrgStatus: string = ""

  constructor(
    public http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string
  }

  ngOnInit(): void {
    this.getInfoOrganization()
  }

  getInfoOrganization() {
    this.http.get("/list_orgs/").subscribe((res: any) => {
      if (res) {
        res.data.map((org: any) => {
          if (org.id === this.currentOrg) {
            this.selectedOrgStatus = org.status
          }
        })
        this.infoOrg = res.data
        this.infoOrg = this.infoOrg.filter(
          (org: any) => org.id === this.currentOrg,
        )
        this.isLoading = false
      }
    })
  }
}
