import { HttpClient } from "@angular/common/http"
import { Component } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { AuthService } from "src/app/core/services/auth.service"
import { CustomSnackbarComponent } from "../../components/custom-snackbar/custom-snackbar.component"

@Component({
  selector: "app-edit-my-profile",
  templateUrl: "./edit-my-profile.component.html",
  styleUrls: ["./edit-my-profile.component.scss"],
})
export class EditMyProfileComponent {
  editMyProfile!: FormGroup
  userData: any
  role: any
  email: any
  family_name: any
  given_name: any
  middle_name: any
  Username: any
  firstName: any
  lastName: any
  middleName: any
  isLoading: boolean = true

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
  ) {}
  ngOnInit(): void {
    this.isLoading = false
    this.lastName = this.authService.family_name
    this.firstName = this.authService.given_name
    this.email = this.authService.email
    this.editMyProfile = this.formBuilder.group({
      id: this.authService.id,
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, Validators.required],
    })

    // this.family_name = this.authService.family_name
    // this.given_name = this.authService.given_name
  }

  submitEditMyProfile() {
    this.http
      .post("update_user/", this.editMyProfile.value)
      .subscribe((res: any) => {
        if (res) {
          this.authService.loadNames()
        }
        this.snackbar.openFromComponent(CustomSnackbarComponent, {
          data: { name: "User updated successfully" },

          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: [
            "my-custom-snackbar-error",
            "mat-toolbar",
            "mat-primary",
          ],
          duration: 10000,
        })
        this.dialog.closeAll()
      })
    ;(error: any) => {
      console.log(error)
    }
  }
}
