import { Component } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MaterialModule } from "src/app/material.module"

@Component({
  selector: "app-how-to-upload",
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: "./how-to-upload.component.html",
  styleUrls: ["./how-to-upload.component.scss"],
})
export class HowToUploadComponent {}
