import { Component, OnInit } from "@angular/core"
import { MatCardAppearance } from "@angular/material/card"
import { MatDialog } from "@angular/material/dialog"
import { TextImportDialogComponent } from "../../../components/dropzone/text-import-dialog/text-import-dialog.component"

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  appearance: MatCardAppearance = "raised"
  dataServer: any

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  clickHandler() {
    const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "761px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe()
  }
}
