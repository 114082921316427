import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-snackbar-upload",
  templateUrl: "./snackbar-upload.component.html",
  styleUrls: ["./snackbar-upload.component.scss"],
})
export class SnackbarUploadComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarUploadComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
