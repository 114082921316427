<section class="container">
  <button class="close-btn" mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content">
    <h2 class="title">Deactivate User Account?</h2>
    <h3 class="email">({{ dataDaiolog.email }})<br />will lose access.</h3>
    <div class="button-wrapper gap15 mt10px">
      <button mat-flat-button [mat-dialog-close]="false" class="cancel-btn">
        Cancel
      </button>
      <button
        mat-flat-button
        color="accent"
        class="text-white"
        class="delete-btn"
        (click)="deleteUser()"
        [disabled]="disableButton"
      >
        Deactivate
      </button>
    </div>
  </div>
</section>
