import { Component } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { CookieService } from "ngx-cookie-service"
import { AuthService } from "src/app/core/services/auth.service"

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<TermsComponent>,
  ) {}

  acceptTerms() {
    this.cookieService.set(this.authService.username, "yes")
    this.dialogRef.close()
    return true
  }
}
