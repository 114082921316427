<div *ngIf="commonService.showFirstImport">
  <section class="bg100">
    <div class="w100 pt180 content-center">
      <h1 class="welcome-title">Welcome to Pyxis</h1>
      <div class="text-center wst mb25px">
        Import your first Sample Set File
      </div>
      <div class="center-flex">
        <button
          mat-flat-button
          color="accent"
          class="text-white import-btn"
          (click)="importCsv()"
        >
          Import Sample Set
        </button>
        <button
          *ngIf="
            this.authService.permissions?.includes(
              'update:organization_users'
            ) || this.authService.permissions?.includes('update:platform_users')
          "
          mat-flat-button
          routerLink="/user-account"
        >
          Manage Users
        </button>
        <button
          *ngIf="this.authService.permissions?.includes('update:user')"
          mat-flat-button
          (click)="editProfile()"
        >
          Edit Profile Info
        </button>
      </div>
    </div>
  </section>
</div>
<div *ngIf="!commonService.showFirstImport">
  <section class="container-column pt30px main-section">
    <mat-sidenav-container class="main-section">
      <mat-sidenav class="main-sidenav" mode="side" position="end" #sidenav>
        <div class="sample-sets-view" (clickOutside)="sidenav.toggle()">
          <div class="cont-sidenav">
            <app-sidenav [element]="element"></app-sidenav>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container-top">
          <article class="container-pars">
            <div class="main-title">
              <span class="title-set">Sample Sets</span><br />
            </div>
            <div class="gap15 content-search-add">
              <mat-form-field class="input-search" appearance="outline">
                <mat-icon matPrefix class="icon-grey">search</mat-icon>
                <input
                  matInput
                  placeholder="Search"
                  [(ngModel)]="searchQuery"
                  (ngModelChange)="changeQuery($event)"
                  #input
                />
              </mat-form-field>
              <button
                mat-flat-button
                (click)="importCsv()"
                matPrefix
                type="button"
                color="accent"
                class="text-white apply-program-btn import-btn"
              >
                Import Sample Set
                <img
                  src="../../../../../assets/icons/upload.svg"
                  class="upload-icon"
                />
              </button>
            </div>
          </article>
          <mat-form-field class="input-new" appearance="outline">
            <mat-icon matPrefix class="icon-grey">search</mat-icon>
            <input
              matInput
              placeholder="Search"
              [(ngModel)]="searchQuery"
              (ngModelChange)="changeQuery($event)"
              #input
            />
          </mat-form-field>
        </div>
        <div class="container">
          <div class="csv-result-table">
            <!-- <div *ngIf="isLoading && !isSearched && !isSorted"> -->
            <div>
              <!-- <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport" -->
              <!-- (scrolledIndexChange)="nextBatch($event)" #viewport> -->
              <table
                *ngIf="isLoading"
                mat-table
                [dataSource]="allSets"
                matSort
                class="mat-elevation-z8 csv-parser-content"
                (matSortChange)="announceSortChange($event)"
                matSortDisableClear
              >
                <ng-container matColumnDef="set_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    sortActionDescription="Sort by name"
                    class="set-column"
                  >
                    Sample Set
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    class="set-column"
                  >
                    <!-- <div [ngClass]="{'before-arrow': element.set_name.length <= 14, 'another-class': element.set_name.length > 14}"> -->
                    <div
                      class="before-arrow"
                      (mouseenter)="element.isClicked = true"
                      (mouseleave)="element.isClicked = false"
                    >
                      <img
                        *ngIf="element.isClicked"
                        src=".../../../../../assets/icons/arrow.right.to.line.alt.svg"
                        class="arrow-img"
                      />
                      <img
                        *ngIf="!element.isClicked"
                        src=".../../../../../assets/icons/arrow.right.to.line.alt.svg"
                        class="hide"
                      />
                      <span class="set-name">{{ element.setName }}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="created_date">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="date-column"
                  >
                    Uploaded on
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ getLocaleDateOfDay(element.createdDate) }}<br />{{
                        getLocaleTimeOfDay(element.createdDate)
                      }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="samples">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="number-samples-column"
                  >
                    No. of samples
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    class="number-samples-column"
                  >
                    <ng-container
                      class="samples-num"
                      *ngIf="element.status === 'CONFIGURED'"
                    >
                      {{ element.samples?.length }}
                    </ng-container>
                    <ng-container *ngIf="element.status === 'PENDING'">
                      <div class="chips">
                        <span class="chips-span chips-inside">
                          {{ element.status | titlecase }}
                        </span>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="concPath">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"></td>
                  </ng-container> -->
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef class="created-column">
                    Created by
                  </th>
                  <td mat-cell *matCellDef="let element" class="created-column">
                    {{ element.email }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="jobresults">
                  <th mat-header-cell *matHeaderCellDef class="jobId-column">
                    Latest Job Results
                  </th>
                  <td mat-cell *matCellDef="let element" class="jobId-column">
                    <ng-container *ngIf="element.attributes">
                      <div
                        class="img-cursor-download"
                        (mouseenter)="element.showImage = true"
                        (mouseleave)="element.showImage = false"
                      >
                        <span
                          mat-button
                          (click)="
                            this.downloadFile(element.attributes.content)
                          "
                          class="cursor-download"
                          >{{
                            getLocaleDateOfAll(element.attributes.createdDate)
                          }}
                        </span>
                        <img
                          *ngIf="element.showImage"
                          src="../../../../../assets/icons/icon-download.svg"
                        />
                        <span
                          *ngIf="!element.showImage"
                          class="invisible-box"
                        ></span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!element.attributes"
                      ><span>No Job Results Found</span>
                    </ng-container>
                  </td>
                </ng-container>
                <!-- Add this new column definition -->
                <ng-container matColumnDef="delete">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="delete-column"
                  ></th>
                  <td mat-cell *matCellDef="let element" class="delete-column">
                    <button
                      *ngIf="element.status === 'PENDING'"
                      mat-icon-button
                      (click)="deleteSample(element, $event)"
                      class="delete-icon-button"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns.concat('delete')"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: displayedColumns.concat('delete')
                  "
                  (click)="selectedRow = row"
                  [ngClass]="{
                    selected: row === selectedRow,
                    highlight: selectedRowIndex == row.id,
                  }"
                  (click)="openSidenav(row)"
                  (click)="highlight(row)"
                ></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4" *ngIf="!noQuery">
                    <span class="pl46px">No data matching the filter</span>
                  </td>
                </tr>
              </table>
              <!-- ***************** -->
              <table
                *ngIf="!isLoading"
                mat-table
                [dataSource]="allSets"
                matSort
                class="mat-elevation-z8 csv-parser-content"
                (matSortChange)="announceSortChange($event)"
                matSortDisableClear
              >
                <ng-container matColumnDef="set_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    sortActionDescription="Sort by name"
                    class="set-column"
                  >
                    Sample Set
                  </th>
                </ng-container>
                <ng-container matColumnDef="created_date">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="date-column"
                  >
                    Uploaded on
                  </th>
                </ng-container>
                <ng-container matColumnDef="samples">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="number-samples-column"
                  >
                    No. of samples
                  </th>
                </ng-container>
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef class="created-column">
                    Created by
                  </th>
                </ng-container>
                <ng-container matColumnDef="jobresults">
                  <th mat-header-cell *matHeaderCellDef class="jobId-column">
                    Latest Job Results
                  </th>
                </ng-container>
                <ng-container matColumnDef="delete">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="delete-column"
                  ></th>
                  <td mat-cell *matCellDef="let element" class="delete-column">
                    <button
                      mat-icon-button
                      (click)="deleteSample(element, $event)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns.concat('delete')"
                ></tr>
              </table>
              <div *ngIf="nextPageLoading || isSearched || isSorted">
                <div class="container-spinner">
                  <div class="spinner">
                    <mat-spinner></mat-spinner>
                  </div>
                </div>
              </div>
              <mat-paginator
                class="paginator"
                [length]="totalSamples"
                [pageSize]="samplesLimit"
                [pageSizeOptions]="[10]"
                (page)="pageChanged($event)"
                *ngIf="isLoading"
              ></mat-paginator>
              <div *ngIf="!isLoading && !this.commonService.isConfigError">
                <div class="container-spinner">
                  <div class="spinner">
                    <mat-spinner></mat-spinner>
                  </div>
                </div>
              </div>
              <!-- </cdk-virtual-scroll-viewport> -->
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </section>
</div>
