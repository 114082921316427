<div class="how-container">
  <div class="container-row closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2 class="dialog-header">How to upload data to Bucket?</h2>
  <mat-tab-group [color]="'accent'">
    <mat-tab label="VIA SYBERDUCK">
      <br />
      <span class="inside-span">Instruction or video here</span>
    </mat-tab>
    <mat-tab label="VIA AWS CONSOLE">
      <br />
      <span class="inside-span">Instruction or video here</span>
    </mat-tab>
    <mat-tab label="VIA AWS CLI">
      <br />
      <span class="inside-span">Instruction or video here</span>
    </mat-tab>
  </mat-tab-group>
  <div class="button-wrapper gap15 mt10px">
    <button mat-flat-button [mat-dialog-close]="false">Close</button>
  </div>
</div>
