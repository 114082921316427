import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"
import { Router } from "@angular/router"
import { CommonService } from "src/app/core/services/commonservice.service"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"

@Component({
  selector: "app-custom-snackbar-arhive",
  templateUrl: "./custom-snackbar-arhive.component.html",
  styleUrls: ["./custom-snackbar-arhive.component.scss"],
})
export class CustomSnackbarArhiveComponent {
  constructor(
    public router: Router,
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    public commonService: CommonService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
