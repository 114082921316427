import { HttpClient } from "@angular/common/http"
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  signal,
  computed,
  AfterViewInit,
} from "@angular/core"
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms"
import { MatSelectChange } from "@angular/material/select"
import { catchError, filter, map, Observable, of, Subject, tap } from "rxjs"
import { RendererFactory2 } from "@angular/core"
import { MatSelect } from "@angular/material/select"
import { ViewChild } from "@angular/core"
import { CommonService } from "../core/services/commonservice.service"
import { AuthService } from "../core/services/auth.service"
import { switchMap } from "rxjs"
import { fromObservable } from "./from-observable"
import { fromSignal } from "./from-signal"

@Component({
  selector: "app-custom-mat-select-search",
  templateUrl: "./custom-mat-select-search.component.html",
  styleUrls: ["./custom-mat-select-search.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMatSelectSearchComponent),
      multi: true,
    },
  ],
})
export class CustomMatSelectSearchComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @Input() selectOptions?: any
  @Input() multiple?: boolean
  @Input() showChipList: boolean = false
  @Input() formControl: any

  @ViewChild("matSelectElement", { static: false }) matSelect!: MatSelect
  searchedSetID: any
  getRequest: any
  filterCtrl = new FormControl()
  selectedOptions: any[] = []
  pageSize = 50
  data = <any>[]
  renderer
  isPending = false
  lastPage = false
  roleToInt: any
  notifier = new Subject()
  firstPage = 0
  init = true
  isOptionSelected = false
  filteredItems: any
  totalRecords: number = 0
  nextPageRecord: number = 0
  selectedSetId: any

  searchInput = signal("")
  currentPage = signal(this.firstPage)
  searchPage: any

  private readonly loading = signal(true)
  private readonly query = signal("")

  private readonly results = fromObservable(
    fromSignal(this.query).pipe(
      filter(() => !this.isOptionSelected),
      switchMap((query) => this.fetchData(query)),
      tap(() => this.loading.set(false)),
    ),
    [],
  )

  public viewModel = computed(() => {
    const searchQuery = this.query()
    const filteredOptions = this.results().filter((option) => {
      if (
        this.searchedSetID &&
        !this.searchedSetID.setName.includes(searchQuery)
      ) {
        return option.id !== this.searchedSetID.id
      }
      return true
    })
    return {
      filteredOptions: filteredOptions,
    }
  })

  constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    public commonService: CommonService,
    public authService: AuthService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }
  ngOnInit(): void {
    this.searchPage = this.currentPage() + 50
    this.searchedSetID = this.commonService.searchedSetID.value
    this.roleToInt = parseInt(this.authService.role)
  }

  listSetsUrl(
    skip: number = 0,
    limit: number = 10,
    keyword: string | null = null,
  ): string {
    let base = "/list_sets/"
    if (this.authService.permissions?.includes("read:platform_sample_sets")) {
      base += "platform/"
    } else if (
      this.authService.permissions?.includes("read:organization_sample_sets")
    ) {
      base += "organization/"
    }
    base += `?skip=${skip}&limit=${limit}`
    if (keyword) {
      base += `&keyword=${keyword}`
    }
    return base
  }

  private fetchData(query: any): Observable<any[]> {
    query = query || ""

    return this.http
      .get(this.listSetsUrl(this.currentPage(), this.pageSize, query))
      .pipe(
        catchError((v) => {
          this.isPending = false
          return of({ results: [] })
        }),
        map((res: any) => {
          this.totalRecords = res.total
          this.nextPageRecord = this.nextPageRecord + 50
          res.data = res.data.filter(
            (item: any) => item.status === "CONFIGURED",
          )
          if (res) {
            if (this.commonService.sampleSetId.value) {
              this.formControl.setValue(this.commonService.sampleSetId.value)
              this.selectedSetId = this.commonService.sampleSetId.value
            }
            if (!this.formControl.value && !this.init) {
              this.formControl.setValue(res.data[0].id)
            }
          }
          this.isPending = false
          if (this.nextPageRecord > this.totalRecords) {
            this.lastPage = true
          }
          if (this.searchedSetID) {
            res.data = [this.searchedSetID, ...res.data]
            var uniq: any = {}
            var arrFiltered = res.data.filter(
              (obj: any) => !uniq[obj.id] && (uniq[obj.id] = true),
            )
            res.data = [...arrFiltered]
          }
          if (
            this.viewModel().filteredOptions.length > 0 &&
            this.currentPage() > this.firstPage
          ) {
            res.data = [...this.viewModel().filteredOptions, ...res.data]
            var uniq: any = {}

            var arrFiltered = res.data.filter(
              (obj: any) => !uniq[obj.id] && (uniq[obj.id] = true),
            )
            res.data = [...arrFiltered]
          }
          return res.data
        }),
      )
  }

  public changeQuery(query: string): void {
    this.currentPage.set(this.firstPage)
    this.loading.set(true)
    this.query.set(query)
    this.nextPageRecord = 0
    this.lastPage = false
  }

  goToNextPage(): void {
    this.isPending = true
    this.currentPage.update((currentPage) => currentPage + 50)
  }

  writeValue(value: any): void {
    if (value) {
      this.selectedOptions = value
      this.commonService.csvRecords.value.map((el: any) => {
        if (el.id === value) {
          this.commonService.selectedItems.push(el)
        }
      })
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
    // Register the change function
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
    // Register the touched function
  }

  setDisabledState(isDisabled: boolean): void {
    // Set the disabled state of the component
  }

  private onChange: (value: any) => void = () => {}
  private onTouched: () => void = () => {}

  onSelectionChange(event: MatSelectChange): void {
    this.commonService.selectedItems = []
    this.commonService.csvRecords.value.map((el: any) => {
      if (el.id === event.value) {
        this.commonService.selectedItems.push(el)
      }
    })
    this.commonService.searchControl.setValue("")
    this.isOptionSelected = true

    this.onChange(this.selectedOptions)
    this.onTouched()
  }

  onOpenedChange(opened: boolean): void {
    if (opened) {
      this.isOptionSelected = false
      if (this.matSelect.panel) {
        const panel = this.matSelect.panel.nativeElement
        this.renderer.listen(panel, "scroll", (event) => {
          if (
            event.target.scrollHeight -
              (event.target.scrollTop + event.target.clientHeight) <=
              150 &&
            !this.commonService.pending &&
            !this.commonService.lastPage
          ) {
            if (!this.isPending && !this.lastPage) {
              this.goToNextPage()
            }
          }
        })
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init = false
    }, 2000)
  }

  removeOption(option: any) {
    const index = this.selectedOptions.indexOf(option)

    if (index >= 0) {
      this.selectedOptions.splice(index, 1)
    }
  }
  ngOnDestroy(): void {
    this.notifier.next(true)
    this.notifier.complete()
    this.commonService.selectedItems = []
  }
}
