<section class="container-row-response bg-login">
  <div class="w50-response-hide"></div>
  <div class="w50-response start-flex">
    <mat-card appearance="outlined" class="center-flex w50 card-login">
      <div class="brand">
        <img src="../../../assets/images/logo-full.png" />
      </div>
      <div class="content-message">
        <span class="title-login">Password Changed!</span>
        <p class="forgot-text">Your password has been changed successfully.</p>
        <button
          mat-flat-button
          type="button"
          color="warn"
          class="ptpb w100 h50 log-btn"
          routerLink="/login"
        >
          Back to Log in
          <img src=".././../../../../assets/images/arrow-r.svg" />
        </button>
      </div>
    </mat-card>
  </div>
</section>
