<mat-toolbar>
  <mat-toolbar-row>
    <div class="start-flex gap15">
      <div class="center-flex">
        <a href="/" alt="Matterworks">
          <img src="../../../assets/images/logo-full.png" class="logo" />
        </a>
      </div>
    </div>
    <div class="start-flex navbar-link-container">
      <button mat-button routerLink="/metadata" routerLinkActive="active-link">
        Metadata
      </button>
      <button mat-button routerLink="/programs" routerLinkActive="active-link">
        Programs
      </button>
      <button
        mat-button
        routerLink="/job-results"
        routerLinkActive="active-link"
      >
        Job Results
      </button>
      <button
        *ngIf="this.authService.permissions?.includes('read:organization')"
        mat-button
        routerLink="/all-organizations"
        routerLinkActive="active-link"
      >
        Organizations
      </button>
      <button
        *ngIf="this.authService.permissions?.includes('read:analyte')"
        mat-button
        (click)="navigateToNova()"
      >
        Analytes
      </button>
    </div>
    <div *ngIf="authService.namesIsLoaded" class="edit-nav">
      <h3 class="username-nav">
        <div class="nav-names">
          {{ this.authService.firstName() }} {{ this.authService.lastName() }}
        </div>
        <div class="nav-org">
          {{ this.authService.organizationName() }}
        </div>
      </h3>
    </div>
    <div
      *ngIf="!authService.namesIsLoaded && !this.commonService.isConfigError"
      class="spinner spinner-nav"
    >
      <mat-spinner class="inside-spinner" [diameter]="40"></mat-spinner>
    </div>
    <div class="avatar-content">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <!-- <img src="../../../assets/images/avatar.png" class="avatar"> -->
        <!-- <span class="circle">SK</span> -->
        <span class="circle"
          ><img src="../../../assets/icons/user.svg" class="avatar"
        /></span>
      </button>
    </div>
    <div></div>
    <mat-menu #menu="matMenu" class="login-container">
      <div *ngIf="authService.namesIsLoaded" class="edit">
        <h3 class="username">
          {{ this.authService.firstName() }} {{ this.authService.lastName() }}
        </h3>
        <button class="edit-btn" routerLink="/my-profile">
          <img src="../../../../assets/icons/edit.svg" />
        </button>
      </div>
      <div
        *ngIf="!authService.namesIsLoaded && !this.commonService.isConfigError"
        class="spinner"
      >
        <mat-spinner class="inside-spinner" [diameter]="40"></mat-spinner>
      </div>

      <h4 class="role">{{ role }}</h4>
      <button
        *ngIf="
          this.authService.permissions?.includes('read:organization_users') ||
          this.authService.permissions?.includes('read:platform_users')
        "
        mat-menu-item
        class="img-icon-menu"
        [routerLink]="'/user-account'"
      >
        <img src="../../../../assets/icons/my-org.svg" />
        <span>My Organizations</span>
      </button>
      <button mat-menu-item class="img-icon-menu" [routerLink]="'/about'">
        <img src="../../../../assets/icons/info.svg" />
        <span>About the App</span>
      </button>
      <!-- <a class="support" href="mailto:support@getmatterworks.com">
            <button mat-menu-item class="img-icon-menu" ><img src="../../../../assets/icons/question.svg"> Contact Support Team</button></a> -->
      <button
        mat-menu-item
        (click)="onLogout()"
        type="submit"
        class="img-icon-menu logout-button"
      >
        <img src="../../../../assets/icons/logout.svg" />Logout
      </button>
      <!-- <div class="inside-container">
                <mat-list role="list" class="list-right">
                    <mat-list-item role="listitem" class="mat-list">
                        <a class="version"><img src="../../../../assets/icons/info.svg"> About the App</a></mat-list-item>
                    <mat-list-item role="listitem" class="mat-list">
                        <a class="version"><img src="../../../../assets/icons/history.svg"> What's new?</a>
                    </mat-list-item>
                    <mat-list-item role="listitem" class="mat-list">
                        <a class="version"><img src="../../../../assets/icons/edit.svg"> Edit profile</a>
                    </mat-list-item>
                    <mat-list-item role="listitem" class="mat-list">
                        <button (click)="onLogout()" mat-button type="submit" ><img src="../../../../assets/icons/logout.svg">Logout</button>
                        
                    </mat-list-item>
                </mat-list>          
            </div> -->
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
