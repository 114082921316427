<section class="container-title">
  <article class="container-row article-container">
    <div class="main-title mt30px">
      <span class="title-set">Organizations</span><br />
    </div>
    <div class="content-search-add mt30px"></div>
  </article>
  <div class="search-add">
    <mat-form-field class="input-new" appearance="outline">
      <mat-icon matPrefix class="icon-grey">search</mat-icon>
      <input
        matInput
        placeholder="Search"
        [(ngModel)]="searchInput"
        (input)="changeQuery(searchInput)"
      />
    </mat-form-field>
    <button
      mat-flat-button
      matPrefix
      type="button"
      color="accent"
      class="text-white apply-program-btn import-btn"
      (click)="openCreateOrgDialog()"
    >
      Add New Organization
    </button>
  </div>
</section>
<div>
  <section class="container-column h-parser main-section">
    <div class="container">
      <div class="csv-result-table job-results">
        <table
          mat-table
          [dataSource]="organizations"
          class="mat-elevation-z8 csv-parser-content"
        >
          <ng-container matColumnDef="org_name">
            <th mat-header-cell *matHeaderCellDef class="created_on">
              <span class="pl46px">Org Name</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="created_on"
            >
              <span class="pl46px">{{ element.name }}<br /></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="created_on">
              <span class="pl46px">Total accounts</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="created_on"
            >
              <span class="pl46px">{{ element.totalAccounts }}<br /></span>
            </td>
          </ng-container>

          <ng-container matColumnDef="registered">
            <th mat-header-cell *matHeaderCellDef class="created_on">
              <span class="pl46px">Registered on</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="created_on"
            >
              <ng-container *ngIf="element.createdDate; else noDate">
                <span class="pl46px"
                  >{{ getLocaleDateOfDay(element.createdDate) }}<br
                /></span>
              </ng-container>
              <ng-template #noDate>
                <span class="pl46px">---<br /></span>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="programs">
            <th mat-header-cell *matHeaderCellDef class="created_on">
              <span class="pl46px">Programs</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="created_on"
            >
              <span class="pl46px">{{ element.programs.length }}<br /></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClicked(row)"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              <span class="pl46px">No data matching the filter</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </section>

  <div *ngIf="isLoading">
    <div class="container-spinner">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
