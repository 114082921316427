import { Component, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { AuthService } from "src/app/core/services/auth.service"
import { EditMyProfileComponent } from "../edit-my-profile/edit-my-profile.component"
import { Router } from "@angular/router"
import { HttpClient } from "@angular/common/http"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CustomSnackbarComponent } from "../../components/custom-snackbar/custom-snackbar.component"
import * as saveAs from "file-saver"
import { map } from "rxjs"
const Papa = require("papaparse")

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent implements OnInit {
  userData: any
  value: any
  downloadAccessKeyUser: any
  role: any
  family_name: any
  given_name: any
  isLoading: boolean = true
  email: any
  username: any

  roleCognito: any

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
  ) {
    this.roleCognito = this.authService.role
    console.log("this.roleCognito", this.roleCognito)
  }
  ngOnInit() {
    this.authService.getSingleUserInfo().subscribe((res: any) => {
      this.isLoading = false
      console.log("getSingleUserInfo", res)
      this.family_name = res.family_name
      this.given_name = res.given_name
      this.email = res.email
      this.username = res.Username
      this.role = res.role_name
    })
    this.role = this.authService.role
  }
  navigateToDialog() {
    const dialogRef = this.dialog.open(EditMyProfileComponent, {
      // const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "450px",
      height: "500px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // this.success(res)
        this.router.navigate(["/my-profile"])
      }
    })
  }
}
