import { NgModule } from "@angular/core"
import { CustomMatSelectSearchComponent } from "./custom-mat-select-search.component"
import { NgxMatSelectSearchModule } from "ngx-mat-select-search"
import { MatSelectModule } from "@angular/material/select"
import { MatFormFieldModule } from "@angular/material/form-field"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HttpClientModule } from "@angular/common/http"
import { MatChipsModule } from "@angular/material/chips"
import { MaterialModule } from "../material.module"

@NgModule({
  declarations: [CustomMatSelectSearchComponent],
  imports: [
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatChipsModule,
    MaterialModule,
  ],
  exports: [CustomMatSelectSearchComponent, MatChipsModule, MaterialModule],
})
export class CustomMatSelectSearchModule {}
