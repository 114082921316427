import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { UserRegistrationService } from "../forgot-password/user-registration.service"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js"
import { environment } from "../../../../../../environments/environment"

export class RegistrationUser {
  // name?: any;
  email!: string
  // phone_number!: string;
  password!: any
}

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  signUpForm!: FormGroup
  errorMessage!: string
  data: any

  constructor(
    public userRegistration: UserRegistrationService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      username: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    })
  }

  signUp() {
    const poolData = {
      UserPoolId: environment.cognito.userPoolId, // Your user pool id here
      ClientId: environment.cognito.userPoolWebClientId, // Your client id here
    }
    const userPool = new CognitoUserPool(poolData)

    const { username, email, password } = this.signUpForm.value

    const attributeList: CognitoUserAttribute[] = [
      new CognitoUserAttribute({ Name: "email", Value: email }),
      // Add more attributes if needed
    ]

    userPool.signUp(
      username,
      password,
      attributeList,
      this.data,
      (err, result) => {
        if (err) {
          console.error("Sign up error:", err)
          // Handle error response
        } else {
          console.log("Sign up successful:", result)
          // Handle successful signup, e.g., show a success message or navigate to another page
        }
      },
    )
  }
}
