<section class="content-err-all">
  <div class="closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <article class="content-errors mb60px">
    <div class="title">Timeout</div>
    <!-- <div class="text-error">
      <span class="text-orange ">Contact the support team </span>
    </div> -->
    <button
      mat-flat-button
      color="accent"
      class="text-white"
      [mat-dialog-close]="true"
    >
      Try again
    </button>
  </article>
</section>
