<section class="bg-sample pt30px">
  <div class="content container-row-response">
    <div class="left-container">
      <article class="container-row head">
        <div class="w80"><span class="title-set">Programs</span><br /></div>
      </article>

      <div *ngIf="this.authService.permissions?.includes('create:job_config')">
        <article class="container-column mine-article card-half mt50px">
          <div class="container-row">
            <div class="container-flex resp-w">
              <span class="visualization">Configuration</span>
              <span class="title-set-card"> Configure Custom Job </span>
            </div>
            <div class="btn-end">
              <button
                type="button"
                mat-button
                class="accent-hover"
                (click)="navigateToConfigureJob()"
              >
                Configure Job
              </button>
            </div>
          </div>
          <div class="center-center">
            <span class="text">
              Configure a custom job as an internal user.</span
            >
          </div>
        </article>
      </div>
      <ng-container *ngFor="let program of programContainer">
        <article
          (mouseover)="currentProgram = program.id"
          (mouseout)="(currentProgram != program.id)"
          class="container-column mine-article card-half mt50px"
        >
          <div class="container-row">
            <div class="container-flex resp-w">
              <span class="visualization">Model</span>
              <span class="title-set-card">
                {{ program.name }}
              </span>
              <div class="text-vers">Versions:</div>
              <div class="container-row">
                <div
                  class="center-center gap8"
                  *ngFor="let version of program.programs"
                >
                  <div class="chips">
                    <span class="chips-span chips-inside">{{
                      version.program_version.replace("v", "")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-end">
              <button
                *ngIf="currentProgram === program.id"
                type="button"
                mat-button
                class="accent-hover"
                (click)="navigateToJob(program)"
              >
                Configure Job
              </button>
            </div>
          </div>
          <div class="center-center">
            <span class="text">
              The {{ program.program_name }} program applies the Pyxis algorithm
              for imputing absolute concentration from data acquired in
              accordance with the Pyxis candles method. The program will read
              all raw files in the sample set, and for all metabolites in the
              specified concentration range of the Pyxis menu provide both an
              absolute concentration and a prediction error.
            </span>
          </div>
        </article>
      </ng-container>
    </div>
  </div>
</section>
