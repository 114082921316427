import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"

@Component({
  selector: "app-custom-snackbar-job",
  templateUrl: "./custom-snackbar-job.component.html",
  styleUrls: ["./custom-snackbar-job.component.scss"],
})
export class CustomSnackbarJobComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarJobComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
