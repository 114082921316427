import { Inject, Injectable } from "@angular/core"
import {
  HttpHeaderResponse,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType,
  HttpXhrBackend,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http"
import { Observable, config } from "rxjs"
import { XhrFactory } from "@angular/common"
import { WebWorkerPool } from "./web-worker-pool"
import {
  HTTP_WEB_WORKER_CLIENT_CONFIG,
  HttpWebWorkerClientConfig,
} from "./http-web-worker-client-config"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { Router } from "@angular/router"
import { AuthService } from "../core/services/auth.service"
import { Dialog403Component } from "../features/feature/components/dialog403/dialog403.component"
import { ErrorsComponent } from "../features/feature/components/errors/errors.component"
import { CustomSnackbarConfigErrComponent } from "../features/feature/components/custom-snackbar-config-err/custom-snackbar-config-err.component"
import { CommonService } from "../core/services/commonservice.service"

@Injectable()
export class WebWorkerHttpBackend extends HttpXhrBackend {
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  currentUrl: any

  private workerPool: WebWorkerPool
  private baseUrl: string

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private commonService: CommonService,

    @Inject(HTTP_WEB_WORKER_CLIENT_CONFIG) config: HttpWebWorkerClientConfig,
    xhrFactory: XhrFactory,
  ) {
    super(xhrFactory)
    this.workerPool = new WebWorkerPool(
      config.maxWorkers || 10,
      config.webWorkerPath,
    )
    this.baseUrl = config.baseUrl || this.getBaseUrl()
  }

  private getBaseUrl(): string {
    const location = document.createElement("a")
    location.href = "/"
    return `${location.protocol}//${location.host}`
  }

  concatenateUrlParts(...parts: any[]) {
    return parts
      .map((part, i) => {
        if (i === 0) {
          // Remove any trailing slash from the first part
          return part.replace(/\/$/, "")
        } else {
          // Remove any leading and trailing slashes from the other parts
          // return part.replace(/^\/|\/$/g, '');
          return part.replace(/^\//g, "")
        }
      })
      .join("/")
  }

  async formDataToFileArrayBuffer(
    formData: FormData,
  ): Promise<{ arrayBuffer: ArrayBuffer; name: string; type: string }> {
    const file = formData.get("file") as File
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () =>
        resolve({
          arrayBuffer: reader.result as ArrayBuffer,
          name: file.name,
          type: file.type,
        })
      reader.onerror = () => reject(reader.error)
      reader.readAsArrayBuffer(file)
    })
  }

  override handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (req.method === "JSONP") {
      throw new Error(
        `Attempted to construct Jsonp request without HttpClientJsonpModule installed.`,
      )
    }
    return new Observable((observer) => {
      // Create a Web Worker instance
      const worker = this.workerPool.getWorker()
      if (!worker) {
        observer.error("Failed to get a web worker from the pool.")
        return
      }

      let headers = new HttpHeaders()

      // Copy all headers from the original request

      req.headers.keys().forEach((key) => {
        headers = headers.set(key, req.headers.getAll(key) ?? "")
      })

      // Add an Accept header if one isn't present already
      if (!headers.has("Accept")) {
        headers = headers.set("Accept", "application/json, text/plain, */*")
      }

      // Auto-detect the Content-Type header if one isn't present already
      if (!headers.has("Content-Type")) {
        const detectedType = req.detectContentTypeHeader()
        // Sometimes Content-Type detection fails
        if (detectedType !== null) {
          headers = headers.set("Content-Type", detectedType)
        }
      }
      ;(async () => {
        // TODO Not all files will be with key 'file'. Must change the way that we check their type.
        if (
          req.body instanceof FormData &&
          req.body.getAll("file").length > 0
        ) {
          const { arrayBuffer, name, type } =
            await this.formDataToFileArrayBuffer(req.body)
          const urlWithParams = this.concatenateUrlParts(
            this.baseUrl,
            req.urlWithParams,
          )
          headers.set("Content-Type", "multipart/form-data")
          worker.postMessage(
            {
              method: req.method,
              url: urlWithParams,
              body: arrayBuffer,
              fileName: name,
              fileType: type,
              headers: Array.from(headers.keys()).map((key) => [
                key,
                headers.getAll(key),
              ]),
              responseType: req.responseType,
            },
            [arrayBuffer],
          )
        } else if (req.body instanceof FormData) {
          const formDataEntries: [string, string | Blob][] = []
          req.body.forEach((value, key) => {
            formDataEntries.push([key, value])
          })
          req = req.clone({ body: formDataEntries })
          const serializedBody = req.serializeBody()

          const urlWithParams = this.concatenateUrlParts(
            this.baseUrl,
            req.urlWithParams,
          )
          // Post a message to the Web Worker with the request data
          worker.postMessage({
            method: req.method,
            url: urlWithParams,
            body: serializedBody,
            headers: Array.from(headers.keys()).map((key) => [
              key,
              headers.getAll(key),
            ]),
            responseType: req.responseType,
          })
        } else {
          const serializedBody = req.serializeBody()

          const urlWithParams = this.concatenateUrlParts(
            this.baseUrl,
            req.urlWithParams,
          )
          // Post a message to the Web Worker with the request data
          worker.postMessage({
            method: req.method,
            url: urlWithParams,
            body: serializedBody,
            headers: Array.from(headers.keys()).map((key) => [
              key,
              headers.getAll(key),
            ]),
            responseType: req.responseType,
          })
        }
      })()
      // Listen for the message from the Web Worker
      worker.onmessage = (event) => {
        // this.workerPool.releaseWorker(worker);
        const { type, response, headers, status, statusText, error } =
          event.data
        const headersObject =
          (headers &&
            headers.reduce((obj: any, [key, value]: [any, any]) => {
              obj[key] = value
              return obj
            }, {})) ||
          {}
        if (type !== undefined) {
          if (type === HttpEventType.Response) {
            // Send HttpResponse through the observer
            if (response !== null && typeof response === "string") {
              if (
                response.startsWith("<!doctype html>") ||
                response.includes("<html>")
              ) {
                this.commonService.isConfigError = true
                let snackBarRef = this.snackBar.openFromComponent(
                  CustomSnackbarConfigErrComponent,
                  {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: [
                      "my-custom-snackbar-error",
                      "mat-toolbar",
                      "mat-primary",
                    ],
                  },
                )
                snackBarRef.afterDismissed().subscribe((res: any) => {
                  if (res.dismissedByAction) {
                  }
                })
                return
              }
            }

            observer.next(
              new HttpResponse({
                body: response,
                headers: new HttpHeaders(headersObject),
                status: status,
                statusText: statusText,
                url: this.baseUrl + req.urlWithParams || undefined,
              }),
            )
            observer.complete()
          } else if (type === HttpEventType.DownloadProgress) {
            // Send progress events through the observer
            observer.next(response)
          } else {
            // Send HttpHeaderResponse through the observer
            observer.next(new HttpHeaderResponse(response))
          }
        } else if (error) {
          // Send HttpErrorResponse through the observer
          this.workerPool.releaseWorker(worker)
          if (!error.status) {
            let errorMessage: any = {}
            errorMessage.status = Number(error.split("status: ")[1])
            errorMessage.error = error.split("status: ")[0]
            // observer.error(new HttpErrorResponse(errorMessage));
          } else {
            // observer.error(new HttpErrorResponse(error));
            observer.error(
              new HttpErrorResponse({
                error: error.response.error || error,
                status: error.response.status || error.status,
                statusText:
                  error.response.detail ||
                  error.response.message ||
                  error.statusText,
                // @ts-ignore
                headers: new HttpHeaders(Object.fromEntries(error.headers)),
                url: this.baseUrl + "/" + req.urlWithParams || undefined,
              }),
            )
          }
        }
        // worker.removeEventListener('message', handleMessage);
        // this.workerPool.releaseWorker(worker);
      }

      // Listen for error events from the Web Worker
      worker.onerror = (event) => {
        // Send HttpErrorResponse through the observer
        observer.error(
          new HttpErrorResponse({
            error: event.message,
            status: 0,
            statusText: "Unknown Error",
            url: req.url,
          }),
        )
      }

      // Unsubscribe/cleanup function
      // return () => {
      // worker.terminate();
      // };
    })
  }
}
