<section class="container">
  <div class="container-row closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h2 mat-dialog-title>Terms and Conditions</h2>
  <div class="button-wrapper gap15 mt10px">
    <button mat-flat-button routerLink="/terms-conditions">Link</button>
    <button
      mat-flat-button
      color="accent"
      class="text-white"
      (click)="acceptTerms()"
    >
      Accept
    </button>
  </div>
</section>
