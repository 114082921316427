<!-- <div>
    <section class="container-row bg-login">
        <div class="w50"></div>
        <div class="w50 start-flex">
            <mat-card appearance="outlined" class="center-flex w50 card-login">
                <div class="content-login">
                    <form class="form-login" method="POST" action="#" role="form">
                        <div class="brand">
                            <img src="../../../assets/images/logo-full.png">
                        </div>
                        <span class="title-login">Register</span>
                        <div *ngIf="errorMessage!=null" class="alert alert-danger">
                            {{ errorMessage }}
                        </div>
                        <mat-form-field appearance="outline">
                            <mat-label for="signupName">Email</mat-label>
                            <input matInput id="signupEmail" type="email" maxlength="50"
                                [(ngModel)]="registrationUser.email" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>                           
                            <input matInput id="signupPassword" type="password" maxlength="25"
                                placeholder="at least 8 characters" length="40" [(ngModel)]="registrationUser.password"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <button mat-flat-button type="submit" color="warn" class="ptpb w100 h50 log-btn"
                            (click)="onRegister()" id="signupSubmit">
                            Create your account
                        </button>

                        <hr>
                        <p>Already have an account? <a routerLink="/login"> <i class="fa fa-fw fa-lock"></i>Login</a>
                        </p>
                    </form>
                </div>
            </mat-card>
        </div>
    </section>
</div> -->

<div>
  <section class="container-row bg-login">
    <div class="w50"></div>
    <div class="w50 start-flex">
      <mat-card appearance="outlined" class="center-flex w50 card-login">
        <div class="content-login">
          <form
            [formGroup]="signUpForm"
            (ngSubmit)="signUp()"
            class="form-login"
          >
            <div class="brand">
              <img src="../../../assets/images/logo-full.png" />
            </div>
            <span class="title-login">Registration</span>
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>

              <input matInput type="text" formControlName="username" required />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Email address</mat-label>
              <input matInput type="email" formControlName="email" required />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label> Password:</mat-label>

              <input
                matInput
                type="password"
                formControlName="password"
                required
              />
            </mat-form-field>
            <button
              mat-flat-button
              type="submit"
              color="warn"
              class="ptpb w100 h50 log-btn"
            >
              Sign Up
            </button>
          </form>
        </div>
      </mat-card>
    </div>
  </section>
</div>
