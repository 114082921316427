<section class="content-err-all">
  <div class="closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <article class="content-errors mb60px">
    <div>
      <img src="../../../../../assets/icons/sad.svg" />
    </div>
    <div class="title">
      {{ data.message }}
      {{ data.statusText }}<br />
      {{ data.status }}
    </div>
    <div class="text-error">
      <span class="text-orange">Contact the support team </span>
    </div>
    <button
      mat-flat-button
      color="warn"
      class="ptpb w100 h50 log-btn"
      routerLink="/"
    >
      Go to Home
    </button>
  </article>
</section>
