import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  HostListener,
  SecurityContext,
} from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"

@Directive({
  selector: "[appTooltip]",
})
export class TooltipDirective {
  @Input("appTooltip") tooltipContent!: string

  private tooltipElement: any = null
  private showTimeout: any

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {}

  @HostListener("mouseenter") onMouseEnter() {
    if (!this.tooltipElement) {
      this.showTimeout = setTimeout(() => {
        this.show()
      }, 200)
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout)
    }
    if (this.tooltipElement) {
      this.hide()
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: any) {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout)
    }
    this.hide()
  }

  show() {
    this.tooltipElement = this.renderer.createElement("span")
    this.renderer.addClass(this.tooltipElement, "tooltip")
    this.renderer.setProperty(
      this.tooltipElement,
      "innerHTML",
      this.sanitizer.sanitize(SecurityContext.HTML, this.tooltipContent),
    )

    const hostPos = this.el.nativeElement.getBoundingClientRect()
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    const top = hostPos.top - 115 + scrollPos // Adjust 40 based on your tooltip height or give an estimated height
    const left = hostPos.left - 50 // Adjust 50 to set how far to the left you want the tooltip

    this.renderer.setStyle(this.tooltipElement, "top", `${top}px`)
    this.renderer.setStyle(this.tooltipElement, "left", `${left}px`)
    this.renderer.setStyle(this.tooltipElement, "position", "absolute")
    this.renderer.appendChild(document.body, this.tooltipElement)
  }

  hide() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement)
      this.tooltipElement = null
    }
  }
}
