<section class="container-row-response bg-login">
  <div class="w50-response-hide"></div>
  <div class="w50-response start-flex">
    <mat-card appearance="outlined" class="center-flex w50 card-login">
      <div class="brand">
        <img src="../../../assets/images/logo-full.png" />
      </div>
      <span class="title-login">Check Your Email</span>
      <p class="forgot-text">
        A link has been sent to
        <span class="bold"> raj&#64;matterworks.com.</span> If you didn’t get
        the link<a routerLink="/newPassword" class="link-orange"> click here</a>

        <button
          mat-flat-button
          type="submit"
          color="warn"
          class="ptpb w100 h50 mt60px log-btn"
          routerLink="/login"
        >
          Log In
        </button>
      </p>
    </mat-card>
  </div>
</section>
