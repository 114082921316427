<section class="bg-sample pt30px">
  <div class="content container-row">
    <div class="left-container">
      <div class="title-set mb30px">Whats New</div>
      <article class="text-grey mb60px" id="10.0.1">
        <h2 class="title-version">Version 10.0.1</h2>
        <span class="date">11/11/2023</span><br />
        New Features:
        <ul>
          <li>
            Gene Search: With our new gene search feature, users can now easily
            search for specific genes and retrieve detailed information about
            their function, structure, and variants.
          </li>
          <li>
            Drug Discovery: We have added a new drug discovery feature that
            allows users to search for drugs based on their mechanism of action,
            target gene, or therapeutic area. Users can also access information
            about drug interactions, side effects, and clinical trials.
          </li>
          <li>
            Clinical Trials: Our new clinical trials feature provides users with
            up-to-date information about ongoing and completed clinical trials
            for various diseases and conditions. Users can search for trials
            based on location, phase, and sponsor.
          </li>
          <li>
            Personalized Dashboard: We have redesigned the app's dashboard to
            provide users with a personalized experience based on their
            preferences and usage history. Users can now easily access their
            favorite genes, drugs, and clinical trials from the dashboard.
          </li>
        </ul>
        Improvements:
        <ul>
          <li>
            Improved Navigation: We have improved the app's navigation to make
            it easier for users to find what they are looking for. Users can now
            access all features from the main menu, and the search bar is more
            prominent.
          </li>
          <li>
            Enhanced User Interface: We have updated the app's user interface to
            improve readability and usability. We have also added new icons and
            graphics to make the app more visually appealing.
          </li>
          <li>
            Bug Fixes: We have fixed several bugs and performance issues
            reported by users in the previous version. The app is now more
            stable and reliable.
          </li>
        </ul>
        We hope you enjoy using our BioTech app version 1.0. Please feel free to
        send us your feedback and suggestions for future updates.
      </article>
      <article class="text-grey mb60px" id="9.09.02">
        <h2 class="title-version">Version 9.09.02</h2>
        <span class="date">11/11/2023</span><br />
        New Features:
        <ul>
          <li>
            Gene Search: With our new gene search feature, users can now easily
            search for specific genes and retrieve detailed information about
            their function, structure, and variants.
          </li>
          <li>
            Drug Discovery: We have added a new drug discovery feature that
            allows users to search for drugs based on their mechanism of action,
            target gene, or therapeutic area. Users can also access information
            about drug interactions, side effects, and clinical trials.
          </li>
        </ul>
        <p>
          Improved Navigation: We have improved the app's navigation to make it
          easier for users to find what they are looking for. Users can now
          access all features from the main menu, and the search bar is more
          prominent.
        </p>
        <p>
          We hope you enjoy using our BioTech app version 1.0. Please feel free
          to send us your feedback and suggestions for future updates.
        </p>
      </article>
      <article class="text-grey mb60px" id="9.09.01">
        <h2 class="title-version">Version 9.09.01</h2>
        <span class="date">11/11/2023</span><br />
        New Features:
        <ul>
          <li>
            Gene Search: With our new gene search feature, users can now easily
            search for specific genes and retrieve detailed information about
            their function, structure, and variants.
          </li>
          <li>
            Drug Discovery: We have added a new drug discovery feature that
            allows users to search for drugs based on their mechanism of action,
            target gene, or therapeutic area. Users can also access information
            about drug interactions, side effects, and clinical trials.
          </li>
        </ul>
        <p>
          Improved Navigation: We have improved the app's navigation to make it
          easier for users to find what they are looking for. Users can now
          access all features from the main menu, and the search bar is more
          prominent.
        </p>
        <p>
          We hope you enjoy using our BioTech app version 1.0. Please feel free
          to send us your feedback and suggestions for future updates.
        </p>
      </article>
    </div>
    <div class="rightCol sideCol">
      <article class="container-column jc-end">
        <div class="card-basic position-fix-about release-card">
          <mat-list role="list" class="list-right">
            <mat-list-item role="listitem" class="mat-list">
              <a class="version" (click)="onClick('10.0.1')"
                >Version 10.0.1</a
              ></mat-list-item
            >
            <mat-list-item role="listitem">
              <a class="version" (click)="onClick('9.09.02')"
                >Version 9.09.02</a
              ></mat-list-item
            >
            <mat-list-item role="listitem">
              <a class="version" (click)="onClick('9.09.01')"
                >Version 9.09.01</a
              ></mat-list-item
            >
          </mat-list>
        </div>
      </article>
    </div>
  </div>
</section>
