import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-snackbar-create-org",
  templateUrl: "./snackbar-create-org.component.html",
  styleUrls: ["./snackbar-create-org.component.scss"],
})
export class SnackbarCreateOrgComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarCreateOrgComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
