import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ToggleService } from "./services/toggle.service"

@NgModule({
  declarations: [
    // PermissionsPipe
  ],
  imports: [CommonModule],
  providers: [ToggleService],
})
export class SharedModule {}
