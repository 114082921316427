import { Component } from "@angular/core"
import { CognitoUtil } from "src/app/core/services/cognito-forgot.service"

@Component({
  selector: "app-check-email",
  templateUrl: "./check-email.component.html",
  styleUrls: ["./check-email.component.scss"],
})
export class CheckEmailComponent {
  user: any
  constructor(public cognitoUtil: CognitoUtil) {}
  ngOnInit() {
    this.cognitoUtil.getCurrentUser()
  }
}
