<section class="content-target">
  <div class="closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <article class="subcontent mb60px">
    <div class="title">Pyxis - Polar Absolute Quantitation</div>
    <div class="subtitle">Include versions in Program:</div>
    <div class="table-target">
      <table mat-table [dataSource]="listTarget" class="mat-elevation-z8">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="th-f">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>Version</th>
          <td mat-cell *matCellDef="let element">{{ element.version }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="button-wrapper gap15 mt10px">
      <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
      <button
        mat-flat-button
        color="warn"
        class="text-white"
        type="button"
        (click)="onApplyClick()"
      >
        Apply
      </button>
    </div>
  </article>
</section>
