import { Inject, Injectable } from "@angular/core"
import { CognitoCallback, CognitoUtil } from "./cognito.service"
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js"
import { RegistrationUser } from "../register/register.component"

import * as AWS from "aws-sdk/global"
import { Router } from "@angular/router"

@Injectable({
  providedIn: "root",
})
export class UserRegistrationService {
  constructor(
    router: Router,
    @Inject(CognitoUtil) public cognitoUtil: CognitoUtil,
  ) {}

  register(user: RegistrationUser, callback: CognitoCallback): void {
    console.log("UserRegistrationService: user is " + user)

    let attributeList = []

    let dataEmail = {
      Name: "email",
      Value: user.email,
    }
    let dataNickname = {
      Name: "name",
      Value: user.email,
    }
    attributeList.push(new CognitoUserAttribute(dataEmail))
    attributeList.push(new CognitoUserAttribute(dataNickname))
    // attributeList.push(new CognitoUserAttribute({
    //     Name: 'phone_number',
    //     Value: user.phone_number
    // }));

    this.cognitoUtil
      .getUserPool()
      .signUp(
        user.email,
        user.email,
        user.password,
        attributeList,
        function (err: any, result: any) {
          if (err) {
            callback.cognitoCallback(err.message, null)
            console.log("err.message " + err.message)
          } else {
            console.log("UserRegistrationService: registered user is " + result)
            callback.cognitoCallback("", result)
          }
        },
      )
  }

  confirmRegistration(
    username: string,
    confirmationCode: string,
    callback: CognitoCallback,
  ): void {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool(),
    }

    let cognitoUser = new CognitoUser(userData)

    cognitoUser.confirmRegistration(
      confirmationCode,
      true,
      function (err, result) {
        if (err) {
          callback.cognitoCallback(err.message, null)
        } else {
          callback.cognitoCallback("", result)
        }
      },
    )
  }

  resendCode(username: string, callback: CognitoCallback): void {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool(),
    }

    let cognitoUser = new CognitoUser(userData)

    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        callback.cognitoCallback(err.message, null)
      } else {
        callback.cognitoCallback("", result)
      }
    })
  }

  // newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
  //     console.log(newPasswordUser);
  //     // Get these details and call
  //     //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
  //     let authenticationData = {
  //         Username: newPasswordUser.username,
  //         // Password: newPasswordUser.existingPassword,
  //     };
  //     let authenticationDetails = new AuthenticationDetails(authenticationData);

  //     let userData = {
  //         Username: newPasswordUser.username,
  //         Pool: this.cognitoUtil.getUserPool()
  //     };

  //     console.log("UserLoginService: Params set...Authenticating the user");
  //     let cognitoUser = new CognitoUser(userData);
  //     console.log("UserLoginService: config is ", cognitoUser, AWS.config);
  //     // cognitoUser.changePassword('oldPassword', 'newPassword', function(err, result) {
  //     //     if (err) {
  //     //         alert(err.message || JSON.stringify(err));
  //     //         return;
  //     //     }
  //     //     console.log('call result: ' + result);
  //     // });
  //     cognitoUser.authenticateUser(authenticationDetails, {
  //         newPasswordRequired: function (userAttributes, requiredAttributes) {
  //             // User was signed up by an admin and must provide new
  //             // password and required attributes, if any, to complete
  //             // authentication.

  //             // the api doesn't accept this field back
  //             delete userAttributes.email_verified;
  //             cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
  //                 onSuccess: function (result) {
  //                     callback.cognitoCallback('', userAttributes);
  //                     console.log(result,'result')
  //                     // this.router.navigate(['/metadata']);
  //                 },
  //                 onFailure: function (err) {
  //                     callback.cognitoCallback(err, null);
  //                 }
  //             });
  //         },
  //         onSuccess: function (result) {
  //             callback.cognitoCallback('', result);
  //         },
  //         onFailure: function (err) {
  //             callback.cognitoCallback(err, null);
  //         }
  //     });
  // }
}
