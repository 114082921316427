<div class="container-row-only row snack-arhive">
  <div class="column w80" *ngIf="data.activeIcon && !data.arhiveIcon">
    <div class="container-row-only">
      <img src="../../../../../assets/icons/check.svg" class="deactive-icon" />
      <div class="column">
        <div class="bold bold-strong">{{ data.name }}</div>
        <div class="normal-text">
          {{ data.email }}
        </div>
      </div>
    </div>
  </div>
  <div class="column w80" *ngIf="data.arhiveIcon && !data.activeIcon">
    <div class="container-row-only">
      <img
        src="../../../../../assets/icons/arhive-snackbar.svg"
        class="deactive-icon"
      />
      <div class="column">
        <div class="bold bold-strong">{{ data.name }}</div>
        <div class="normal-text">
          {{ data.email }}
        </div>
      </div>
    </div>
  </div>
  <div class="column">
    <button mat-button (click)="snackBarRef.dismissWithAction()">
      <span class="text-orange"> Close </span>
    </button>
  </div>
</div>
