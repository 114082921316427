import { HttpEvent } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class WorkerserviceService {
  activeWorkers: Worker[] = []

  callWorker(data: any, callback?: any, error?: any) {
    const worker = new Worker(new URL("../../app.worker", import.meta.url))
    this.activeWorkers.push(worker)
    worker.onmessage = ({ data }) => {
      callback(data)
      /*}else{
      error(data)
      }*/
    }
    worker.postMessage(data)
  }

  callWorkerWithObservable(data: any) {
    const worker = new Worker(new URL("../../app.worker", import.meta.url))
    worker.postMessage(data)
    this.activeWorkers.push(worker)
    return new Observable<HttpEvent<any>>((observer) => {
      worker.onmessage = ({ data }) => {
        observer.next(data)
      }
    })
  }
  // terminateAll() {
  //   console.log('terminateAll')
  //   this.activeWorkers.forEach((worker) => {
  //     worker.terminate();
  //   } )
  //   this.activeWorkers = [];
  // }
}
