import { Component, OnDestroy, OnInit } from "@angular/core"
import { NavigationEnd, NavigationStart, Router } from "@angular/router"
import { filter, Subject, Subscription, takeUntil } from "rxjs"
import { AuthService } from "./core/services/auth.service"
import { WorkerserviceService } from "./core/services/workerservice.service"
import { BnNgIdleService } from "bn-ng-idle"
import { environment } from "../environments/environment"
import { CommonService } from "./core/services/commonservice.service"
import { MatDialog } from "@angular/material/dialog"
import { ErrorsComponent } from "./features/feature/components/errors/errors.component"

declare const gtag: Function

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "pyxis"
  private roles: string[] = []
  isLoggedIn: boolean = false
  // showAdminBoard = false
  // showModeratorBoard = false
  username?: string
  savedEmail: any
  currentUrl = this.router.url
  eventBusSub?: Subscription
  showHeader: boolean = true
  notifier = new Subject()
  currentRoute: any

  subscription: any
  browserRefresh: any
  constructor(
    private dialog: MatDialog,
    private bnIdle: BnNgIdleService,
    public router: Router,
    public authService: AuthService,
    public workerService: WorkerserviceService,
    private commonService: CommonService,
  ) {
    this.currentRoute = this.router.url
    const worker = new Worker(new URL("./app.worker", import.meta.url))
    if (typeof Worker !== "undefined") {
      // Create a new
      worker.onmessage = ({ data }) => {
        console.log(`page got message: ${data}`)
      }
      worker.postMessage("hello")
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === "/") {
        } else {
          this.commonService.isMetadataOpen = false
          this.dialog.closeAll()
        }
      }
    })
  }

  ngOnInit(): void {
    this.login()
    this.bnIdle
      .startWatching(parseInt(environment.timeout))
      .subscribe((res: any) => {
        if (!this.commonService.isMetadataOpen) {
          this.authService.onLogout()
          this.router.navigateByUrl("/login")
          this.dialog.open(ErrorsComponent, {
            width: "360px",
            hasBackdrop: true,
            height: "auto",
            panelClass: "dialogJobRight",
            data: {
              status: "401",
              statusText: "Unauthorized",
              message: "User inactive for 1 hour",
            },
          })
          this.commonService.isMetadataOpen = true
        }
      })

    this.savedEmail = localStorage.getItem("email")

    // if (this.isLoggedIn) {
    //   this.showAdminBoard = this.roles.includes("ROLE_ADMIN")
    //   this.showModeratorBoard = this.roles.includes("ROLE_MODERATOR")
    // }

    this.commonService.savedEmail
      .pipe(takeUntil(this.notifier))
      .subscribe((res) => {
        this.savedEmail = res
      })
  }
  login(): void {
    this.authService.isLoggedIn().subscribe((isAuth) => {
      if (isAuth == true) {
        this.authService.loadNames().then(() => {
          this.isLoggedIn = true
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.notifier.next(true)
    this.notifier.complete()
  }
}
