import { environment } from "../../../environments/environment"
import { Injectable } from "@angular/core"
import { DynamoDBService } from "./ddb.service"
import {
  CognitoCallback,
  CognitoUtil,
  LoggedInCallback,
} from "./cognito-forgot.service"
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js"
import * as AWS from "aws-sdk/global"
import * as STS from "aws-sdk/clients/sts"

@Injectable({
  providedIn: "root",
})
export class UserLoginService {
  private onLoginSuccess = (
    callback: CognitoCallback,
    session: CognitoUserSession,
  ) => {
    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(
      session.getIdToken().getJwtToken(),
    )
    // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
    // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
    // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
    // security credentials. The identity is then injected directly into the credentials object.
    // If the first SDK call we make wants to use our IdentityID, we have a
    // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
    // very innocuous API call that forces this behavior.
    let clientParams: any = {}
    if (environment.sts_endpoint) {
      clientParams.endpoint = environment.sts_endpoint
    }
    let sts = new STS(clientParams)
    sts.getCallerIdentity(function (err: any, data: any) {
      // @ts-ignore
      callback.cognitoCallback(null, session)
    })
  }

  private onLoginError = (callback: CognitoCallback, err: any) => {
    callback.cognitoCallback(err.message, null)
  }

  constructor(
    public ddb: DynamoDBService,
    public cognitoUtil: CognitoUtil,
  ) {}

  authenticate(username: string, password: string, callback: CognitoCallback) {
    let authenticationData = {
      Username: username,
      Password: password,
    }
    let authenticationDetails = new AuthenticationDetails(authenticationData)

    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool(),
    }
    let cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) =>
        callback.cognitoCallback(`User needs to set password.`, null),
      onSuccess: (result) => this.onLoginSuccess(callback, result),
      onFailure: (err) => this.onLoginError(callback, err),
      // mfaRequired: (challengeName, challengeParameters) => {
      //     callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
      //         cognitoUser.sendMFACode(confirmationCode, {
      //             onSuccess: result => this.onLoginSuccess(callback, result),
      //             onFailure: err => this.onLoginError(callback, err)
      //         });
      //     });
      // }
    })
  }

  forgotPassword(username: string, callback: CognitoCallback) {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool(),
    }

    let cognitoUser = new CognitoUser(userData)

    cognitoUser.forgotPassword({
      onSuccess: function () {},
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null)
      },
      inputVerificationCode() {
        //@ts-ignore
        callback.cognitoCallback(null, null)
      },
    })
  }

  confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string,
    callback: CognitoCallback,
  ) {
    let userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool(),
    }

    let cognitoUser = new CognitoUser(userData)

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        //@ts-ignore
        callback.cognitoCallback(null, null)
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null)
      },
    })
  }

  logout() {
    this.ddb.writeLogEntry("logout")
    this.cognitoUtil.getCurrentUser()?.signOut()
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null)
      throw "UserLoginService: Callback in isAuthenticated() cannot be null"

    let cognitoUser = this.cognitoUtil.getCurrentUser()

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          console.log(
            "UserLoginService: Couldn't get the session: " + err,
            err.stack,
          )
          callback.isLoggedIn(err, false)
        } else {
          console.log("UserLoginService: Session is " + session.isValid())
          callback.isLoggedIn(err, session.isValid())
        }
      })
    } else {
      console.log("UserLoginService: can't retrieve the current user")
      callback.isLoggedIn("Can't retrieve the CurrentUser", false)
    }
  }
}
