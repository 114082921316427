import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

@Injectable({
  providedIn: "root",
})
export class TooltipService {
  private dataSubject = new BehaviorSubject<any>(null)
  public data$ = this.dataSubject.asObservable()

  setData(data: any) {
    console.log("hoooppppsss", data)
    this.dataSubject.next(data)
  }
}
