<div class="container-row-only">
  <div class="column w80 job-btn">
    <div class="column w80 job-btn">
      <div class="bold mt6px">
        Either some files have already been imported or do not match those
        specified in the metadata file.<br />Please reselect your files.
      </div>
    </div>
  </div>
  <div class="btn-close">
    <button mat-button (click)="snackBarRef.dismiss()">
      <span class="text-orange">Close</span>
    </button>
  </div>
</div>
