<div mat-dialog-content class="dialog-container">
  <div class="container-row closing-btn">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="container-row">
    <span class="font24px bold" mat-dialog-title>Import Metadata</span>
  </div>
  <mat-stepper [linear]="isLinear" #stepper>
    <mat-step [completed]="active && isButtonClicked">
      <ng-template matStepLabel
        ><span class="label-stepper">Upload File (.csv)</span></ng-template
      >
      <ngx-dropzone
        [multiple]="false"
        class="drag-and-drop"
        (change)="onSelect($event)"
        [processDirectoryDrop]="true"
        [ngClass]="statusClass"
      >
        <ngx-dropzone-label class="border-dashed">
          <div *ngIf="!isLoading" class="content-upload">
            <img src="../../../assets/images/upload.png" />
            Drag and drop your file here or click to upload.
          </div>
          <div *ngIf="isLoading" class="container-spinner">
            <div class="spinner"><mat-spinner></mat-spinner></div>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-preview
          *ngFor="let f of allFiles"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label class="dropzone-label"
            >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
          >
        </ngx-dropzone-preview>
      </ngx-dropzone>
      <!--
                !!!! if for empty file
                <div class="container-row text-red-info m40-info-red">
                <mat-icon>info</mat-icon><span>.CSV doesn’t have columns</span>
            </div> -->
      <div class="button-wrapper gap15">
        <button
          mat-button
          matStepperPrevious
          [mat-dialog-close]="false"
          type="button"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="accent"
          class="text-white"
          data-attr="btn"
          [ngClass]="{ active: active === true }"
          [disabled]="allFiles.length === 0 || !active"
          (click)="onClick(stepper)"
          type="button"
        >
          Next
          <span class="img-icon"
            ><img src="../../../assets/images/arrow-r.svg"
          /></span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel
        ><span class="label-stepper"
          >Select Columns to Import</span
        ></ng-template
      >
      <span class="text-white bold">Import Metadata</span>
      <!-- <table mat-table [dataSource]="dataSource.data" class="mat-elevation-z8">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (click)="toggleAllRows()" [checked]="isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (mousedown)="$event.stopPropagation()" (click)="select(row)"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="importingFileColumnName" class="first-cell">
                    <th mat-header-cell *matHeaderCellDef> Importing Column Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.importCol}} </td>
                </ng-container>
                <ng-container matColumnDef="dataType">
                    <th mat-header-cell *matHeaderCellDef> Data Type </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-form-field appearance="outline" class="table-dropdown">
                            <mat-select [value]="element.type" (selectionChange)="changeSelection($event, element)">
                                <mat-option *ngFor="let option of options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pyxisColumn">
                    <th mat-header-cell *matHeaderCellDef> Pyxis Column </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row">
                </tr>
            </table> -->
      <form [formGroup]="sampleSetConfigForm">
        <div class="mat-container">
          <div class="first-container">
            <ng-container>
              <div class="set-identifier">Sample Set Identifier is *</div>
              <mat-form-field appearance="outline" class="table-dropdown">
                <mat-select formControlName="setNameField">
                  <mat-option
                    *ngFor="let option of dataSource.data"
                    [value]="option.name"
                  >
                    {{ option.name }}
                    <!-- {{option | json}} -->
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
          <div>
            <ng-container>
              <div class="set-identifier">Sample Name Identifier is *</div>
              <mat-form-field appearance="outline" class="table-dropdown">
                <mat-select formControlName="sampleNameField">
                  <mat-option
                    *ngFor="let option of dataSource.data"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
          <div>
            <ng-container>
              <div class="set-identifier">Raw file path *</div>
              <mat-form-field appearance="outline" class="table-dropdown">
                <mat-select formControlName="sampleFileField">
                  <mat-option
                    *ngFor="let option of dataSource.data"
                    [value]="option.name"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </form>
      <div class="button-wrapper gap15 mt10px">
        <button mat-button matStepperPrevious [mat-dialog-close]="false">
          Cancel
        </button>
        <button
          mat-flat-button
          color="accent"
          matStepperNext
          class="text-white"
          (click)="success()"
          [disabled]="
            !this.sampleSetConfigForm.controls.sampleFileField.value ||
            !this.sampleSetConfigForm.controls.sampleNameField.value ||
            !this.sampleSetConfigForm.controls.setNameField.value
          "
        >
          Import <img src="../../../../../../assets/icons/upload.svg" />
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
