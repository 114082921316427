<section class="bg-sample pt30px">
  <div class="content container-row-only">
    <div class="left-container text-grey">
      <article *ngIf="!isLoading">
        <div class="title-set mb30px">My profile</div>
        <div class="container-row-only">
          <div class="left-container">
            <article class="container-column mine-article card-half mt50px">
              <div class="div-container">
                <h2 class="name">
                  {{ this.authService.given_name }}
                  {{ this.authService.family_name }}
                </h2>
                <button mat-flat-button (click)="navigateToDialog()">
                  Edit
                </button>
              </div>
              <div class="my-profile-info-div">
                <span class="my-profile-info"
                  >Email: {{ this.authService.email }}</span
                >
              </div>
              <div class="my-profile-info-div" *ngIf="this.authService.role">
                <span class="my-profile-info"
                  >Role: {{ this.authService.role_name }}</span
                >
              </div>
              <div class="my-profile-info-div">
                <span class="my-profile-info"
                  >Id: {{ this.authService.id }}</span
                >
              </div>
              <div class="my-profile-info-div">
                <span class="my-profile-info"
                  >Org: {{ this.authService.organization_name }}</span
                >
              </div>
            </article>
          </div>
        </div>
      </article>
      <div *ngIf="isLoading">
        <div class="container-spinner">
          <div class="spinner">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rightCol sideCol"
      [ngClass]="roleCognito === '0' ? 'user-hide' : 'admin-show'"
    >
      <article class="container-column jc-end">
        <div class="card-basic my-card position-fix-about">
          <mat-list role="list" class="list-right">
            <mat-list-item role="listitem"
              ><a routerLink="/user-account"
                >Organization Info</a
              ></mat-list-item
            >
            <mat-list-item class="clicked" role="listitem"
              ><a routerLink="/my-profile">My Profile</a></mat-list-item
            >
          </mat-list>
        </div>
      </article>
    </div>
  </div>
</section>
