import { Component, OnInit } from "@angular/core"
import { MatCardAppearance } from "@angular/material/card"
import { MatDialog } from "@angular/material/dialog"
import { TextImportDialogComponent } from "../../components/dropzone/text-import-dialog/text-import-dialog.component"
import { HttpClient } from "@angular/common/http"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import { Subject, BehaviorSubject, takeUntil } from "rxjs"

interface ColumnNames {
  [key: string]: string
}
@Component({
  selector: "app-try-again",
  templateUrl: "./try-again.component.html",
  styleUrls: ["./try-again.component.scss"],
})
export class TryAgainComponent implements OnInit {
  displayedColumns: any[] = [
    "setName",
    "createdDate",
    "samples",
    "concPath",
    "created",
  ]
  notifier = new Subject()
  columnNames: ColumnNames = {}
  csvRecords = new BehaviorSubject(<any>[])
  dataSource = new MatTableDataSource(this.displayedColumns)
  constructor(
    public http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  tryAgain() {
    // setTimeout(() => {
    //   this.http.get(`/list_sets/`, {
    //     context: setMetadata({
    //       http: true,
    //       storage: {
    //         db: 'csvDB'
    //       }
    //     })
    //   }).pipe(takeUntil(this.notifier)).subscribe((response: any) => {
    //     if (response.length === 0) {
    //       this.router.navigateByUrl('/sample-set')
    //     }
    //     this.columnNames = response[0]?.columnNames;
    //     this.csvRecords.next(response);
    //     this.dataSource = new MatTableDataSource(response);
    //     this.dataSource.filterPredicate = function(data, filter: any): boolean {
    //       return data.setName.toLowerCase().includes(filter)
    //   };
    //     this.dataSource.filteredData.sort((a: any, b: any) => {
    //       return <any>new Date (b.createdDate) - <any>new Date(a.createdDate)
    //     });
    //   })
    // }, 500)
    this.dialog.closeAll()
  }
}
