<section class="bg-sample pt30px">
  <article class="container-column center-flex">
    <div class="container-error-text">
      <div>
        <img
          src="../../../../../assets/icons/containers-and-cloud-native.svg"
        />
      </div>
      <div class="title">No jobs have been run yet.</div>
      <div class="text mb30px">Job runs and status will appear here.</div>
      <!-- <div class="container-row gap15">
                <button mat-flat-button color="warn" class="ptpb h50 log-btn" routerLink="/">Go to Home
                </button>
                <button mat-button color="accent" class="ptpb h50 log-btn">Previous Page</button>
            </div> -->
    </div>
  </article>
</section>
