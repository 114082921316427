import { Injectable } from "@angular/core"
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http"
import {
  EMPTY,
  Observable,
  catchError,
  config,
  delay,
  map,
  mergeMap,
  of,
  pipe,
  retry,
  retryWhen,
  tap,
  throwError,
} from "rxjs"
import { AuthService } from "../services/auth.service"
import { MatDialog, MatDialogRef } from "@angular/material/dialog"
import { Dialog403Component } from "src/app/features/feature/components/dialog403/dialog403.component"
import { ErrorsComponent } from "src/app/features/feature/components/errors/errors.component"
import { Router } from "@angular/router"
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CommonService } from "../services/commonservice.service"

@Injectable()
export class WebWorkerInterceptor implements HttpInterceptor {
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  currentUrl: any

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    public commonService: CommonService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken()

    if (authToken) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + authToken),
      })
    } else {
      console.error("No token provider", request.url)
    }

    let config = new MatSnackBarConfig()
    config.panelClass = [
      "my-custom-snackbar-error",
      "mat-toolbar",
      "mat-primary",
    ]
    config.verticalPosition = this.verticalPosition
    config.horizontalPosition = this.horizontalPosition
    config.duration = 10000

    return next.handle(request).pipe(
      catchError((err) => {
        if (err) {
          switch (err.status) {
            case 502:
              this.snackBar.open(err.message, "Close", config)
              break
            case 504:
              this.dialog.closeAll()
              if (this.currentUrl.url === "/job-result") {
                this.snackBar.open(err.status, "Close", config)
              } else {
                this.router.navigate(["/geteway"])
              }
              break
            case 500:
              const htmlRegex = /<\s*html.*?>/i
              const titleMatch = /<h1>(.*?)<\/h1>/
              let errorMessages
              const currentStep = this.commonService.currentStep

              if (currentStep !== 1) {
                if (htmlRegex.test(err.error.response)) {
                  const match = err.error.response.match(titleMatch)
                  if (match) {
                    err.statusText = match[1]
                  }
                  errorMessages = [err.statusText]
                } else {
                  try {
                    errorMessages = Object.values(
                      JSON.parse(err.error.response),
                    )
                  } catch (jsonError) {
                    console.error(
                      "Failed to parse as JSON:",
                      err.error.response,
                    )
                  }
                }

                // Check if errorMessages is an array and has at least one element
                let displayMessage = "Error occurred"
                if (Array.isArray(errorMessages) && errorMessages.length > 0) {
                  // Check if errorMessages[0] has 'error' property
                  displayMessage = errorMessages[0].error
                    ? errorMessages[0].error
                    : errorMessages[0]
                }

                const dialogRef = this.dialog.open(ErrorsComponent, {
                  width: "360px",
                  height: "auto",
                  hasBackdrop: true,
                  panelClass: "error500",
                  data: {
                    status: err.status,
                    statusText: err.statusText,
                    message: displayMessage,
                  },
                })

                dialogRef.backdropClick().subscribe(() => {
                  this.commonService.clicked = !this.commonService.clicked
                })
              } else if (currentStep === 1) {
                let displayMessage = "Error occurred"
                if (err.error && typeof err.error.response === "string") {
                  try {
                    const errorResponse = JSON.parse(err.error.response)
                    if (errorResponse && errorResponse.error) {
                      displayMessage = errorResponse.error
                    }
                  } catch (parseError) {
                    console.error(
                      "Error parsing err.error.response:",
                      parseError,
                    )
                  }
                }
                if (err.error) {
                  const dialogRef = this.dialog.open(ErrorsComponent, {
                    width: "360px",
                    height: "auto",
                    hasBackdrop: true,
                    panelClass: "error500",
                    data: {
                      status: err.status,
                      statusText: err.statusText,
                      message: displayMessage,
                    },
                  })
                } else {
                  this.snackBar.open("Internal Server Error", "Close", config)
                }
              } else if (this.currentUrl.url === "/my-profile") {
                // Handle specific case for '/my-profile' URL
              }

              return EMPTY
            case 404:
              this.router.navigate(["/not-found"], {
                queryParams: { status: err.status, statusText: err.statusText },
              })
              break
            case 403:
              this.currentUrl = this.router.routerState.snapshot
              if (this.currentUrl.url === "/metadata") {
                this.snackBar.open(err.status, "Close", config)
              } else {
                this.dialog.closeAll()
                this.dialog.open(Dialog403Component, {
                  width: "360px",
                  height: "auto",
                  hasBackdrop: true,
                  panelClass: "error403",
                  data: {
                    status: err.status,
                    statusText: err.statusText,
                    message: err.body?.message,
                    ok: err.ok,
                  },
                })
              }
              break
            // case 401:
            // this.authService.onLogout();
            // this.dialog.closeAll();
            // this.dialog.open(ErrorsComponent, {
            //   width: '360px',
            //   hasBackdrop: true,
            //   height: 'auto',
            //   panelClass: 'dialogJobRight',
            //   data: { status: err.status, statusText: err.statusText, message: 'Access token expired', ok: err.ok }

            // });
            // break;
            case 400:
              this.authService.onLogout()
              this.dialog.closeAll()
              this.dialog.open(ErrorsComponent, {
                width: "360px",
                hasBackdrop: true,
                height: "auto",
                panelClass: "dialogJobRight",
                data: {
                  status: err.status,
                  statusText: err.statusText,
                  message: err.error.message,
                },
              })
              break
            case 200:
              console.log("200", request)
              next.handle(request)
          }
        }
        // return throwError(() => err);
        if (authToken) {
          // Clone the request and add the authentication token to the headers
          const authReq = request.clone({
            headers: request.headers.set(
              "Authorization",
              `Bearer ${authToken}`,
            ),
          })
          console.log(authReq)
          return next.handle(authReq)
        } else {
          // If there is no token, proceed with the original request
          return next.handle(request)
        }
      }),
    )
  }
}
