import { Component, Inject } from "@angular/core"
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
  selector: "app-custom-snackbar-metadata",
  templateUrl: "./custom-snackbar-metadata.component.html",
  styleUrls: ["./custom-snackbar-metadata.component.scss"],
})
export class CustomSnackbarMetadataComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarMetadataComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
