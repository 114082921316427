<section class="bg100">
  <div class="container-column">
    <div class="w100 mt180 content-center">
      <h1 class="welcome-title">Welcome to Pyxis</h1>
      <div class="text-center wst mb25px">
        Import your first Sample Set File
      </div>
      <div class="center-flex">
        <button
          mat-flat-button
          color="accent"
          class="text-white"
          (click)="clickHandler()"
        >
          Import .csv file
        </button>
      </div>
    </div>
  </div>
</section>
