<section class="container-row-response bg-login">
  <div class="w50-response-hide"></div>
  <div class="w50-response start-flex">
    <mat-card appearance="outlined" class="center-flex w50 card-login">
      <div class="brand">
        <img src="../../../../../../assets/images/logo-full.png" />
      </div>
      <span class="title-login">Forgot Password</span>
      <p class="forgot-text">
        Enter your email address. We’ll send you a verification code to reset
        your password
      </p>
      <form class="form-login">
        <mat-form-field appearance="outline">
          <mat-label class="control-label" for="signupEmail"
            >Email address</mat-label
          >
          <input
            matInput
            name="email"
            id="signupEmail"
            type="email"
            maxlength="320"
            class="form-control"
            [(ngModel)]="email"
            [ngModelOptions]="{ standalone: true }"
            email
            placeholder="Email address"
            autofocus
            required
          />
        </mat-form-field>
        <mat-error *ngIf="errorMessage != null" class="errors">
          <span>{{ errorMessage }}</span
          ><br />
        </mat-error>
        <div class="send-btn form-group">
          <button
            mat-flat-button
            (click)="onNext()"
            id="nextPage"
            type="submit"
            color="warn"
            class="ptpb w100 h50 log-btn"
          >
            Send
          </button>
        </div>
        <div *ngIf="!isLogged" class="back-to-log">
          <button
            mat-button
            type="button"
            routerLink="/login"
            class="font1 bold"
          >
            Back to Log In
          </button>
        </div>
        <div *ngIf="isLogged" class="back-to-log">
          <button
            mat-button
            type="button"
            routerLink="/my-profile"
            class="font1 bold"
          >
            Back to My Profile
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</section>
