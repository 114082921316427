import { Component, OnInit } from "@angular/core"
import { NavigationStart, Router } from "@angular/router"
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js"
import { AuthService } from "../../services/auth.service"

import { WorkerserviceService } from "../../services/workerservice.service"
import { db } from "../../../../db"
import { environment } from "../../../../environments/environment"
import { Observable, of } from "rxjs"
import { MatDialog } from "@angular/material/dialog"
import { CommonService } from "../../services/commonservice.service"

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  isLoggedIn: boolean = false
  user: any
  username: any
  role: any
  // name: string = 'Name Last Name'
  name!: string
  family_name: any
  given_name: any
  currentRoute: any

  constructor(
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    public workerService: WorkerserviceService,
    public commonService: CommonService,
  ) {
    this.authService.namesIsLoaded = false
  }

  ngOnInit(): void {
    this.authService.getSingleUserInfo().subscribe((res: any) => {
      this.username = this.authService.username
      this.role = this.authService.role_name
      this.family_name = this.authService.family_name
      this.given_name = this.authService.given_name
      this.name = this.authService.given_name
    })
  }

  onLogout(): void {
    this.authService.onLogout()
    this.dialog.closeAll()
    localStorage.clear()
  }

  navigateToNova() {
    window.location.href = "/nova/list-analytes"
  }
}
